//third-party
import { multiply    } from "dinero.js";
import { LocalDate  } from "@js-joda/core";
import { Card, Form, ListGroup } from "react-bootstrap";

//spotsync
import { Money, showMoney, showMoneyShort } from "../Data/Money";
import { showDate } from "../Data/Date";
import { ParkerPlanInvite } from "../Data/ApiTypes";
import { AddSubtract } from "./AddSubtract";
import { useState, useEffect } from "react";
import { Api } from "../Data/Api";

interface MonthlyParkingInviteDetailsProps {
    color?:         string;    
    invite:         ParkerPlanInvite;
    start:          LocalDate;
    disabled?:      boolean;
    quantity?:      number;
    setQuantity?:   ( qty: number ) => void;
}

export function MonthlyParkingInviteDetails( props: MonthlyParkingInviteDetailsProps ) {
    const { color = "primary",
        invite,
        start,
        quantity,
        setQuantity,
        disabled = false
    } = { ...props };

    const [proratedFee, setProratedFee] = useState<Money>();
    async function subscribePreview() {
        const res = await Api.subscriptionInvitePreview( invite.listedPlan.planId, {
            parkerPlanInviteId: invite.parkerPlanInviteId,
            start:              LocalDate.now(),
            quantity:           quantity!
        } );
        if( res.isOk ) {
            setProratedFee( res.value.price );
        }
    }

    useEffect( () => {
        subscribePreview();
    }, [quantity] );

    const rate = multiply( invite.privatePrice, quantity ?? 1 );
    return <Card className={`border-2 border-${color} my-1`}>
        <Card.Header className={`text-center bg-${color}-subtle`}>
            Monthly Parking Options
        </Card.Header>
        <Card.Body>            
            <ListGroup.Item>
                <div className="d-flex flex-row border rounded-4 p-3 m-2 fs-7">
                    <Form.Check
                        className="m-0 flex-grow-1"
                        type="radio"
                        label={`${invite.listedPlan.name}`}
                        checked={true}
                        inline
                        readOnly />
                    <span className="fw-bold">
                        {showMoneyShort( invite.listedPlan.price )}
                    </span>
                </div>
            </ListGroup.Item>
            <div className="text-secondary">
                {invite.listedPlan.description}
            </div>
            <div className="mt-2 w-auto gap-3" style={{ display: "inline-grid", gridTemplateColumns: "auto 1fr 1fr" }}>
                {( quantity !== undefined && setQuantity !== undefined ) && <>
                    <div className="text-start d-flex align-items-center">
                        Spots
                    </div>
                    <div className="text-end">
                        <div className="d-inline-flex align-items-center justify-content-start justify-content-md-center gap-1">
                            <AddSubtract quantity={quantity} set={ qty => disabled !== true && setQuantity( qty ) } />
                        </div>
                    </div>
                    <div></div>
                </>}

                <div className="text-start">Start Date</div>
                <div className="text-end">{showDate( start )}</div>
                <div></div>

                <div className="text-start">First Payment</div>
                <div className="text-end fw-bold">{proratedFee && showMoney( proratedFee )}</div>
                <div></div>

                <div className="text-start">Monthly Payment</div>
                <div className="text-end fw-bold">{showMoney( rate )}</div>
                <div></div>
            </div>
        </Card.Body>
    </Card>;
}