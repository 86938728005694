import React from 'react';
import type { FC } from 'react';
import { Tabs, Tab, Card, Container, Row, Col, Alert } from 'react-bootstrap';

interface ShadowBoxProps {
  className: string;
  label: string;
}

const ShadowBox: FC<ShadowBoxProps> = ({ className, label }): JSX.Element => (
  <div className="p-4">
    <div className={`w-32 h-32 bg-white rounded ${className} transition-shadow duration-300`}>
      <div className="p-2 text-xs text-gray-600">{label}</div>
    </div>
  </div>
);

interface ResponsiveNoteProps {
  breakpoint: string;
}

const ResponsiveNote: FC<ResponsiveNoteProps> = ({ breakpoint }): JSX.Element => (
  <Alert variant="warning" className="mb-4">
    Note: {breakpoint} classes will apply at {breakpoint} breakpoint and up
    ({breakpoint === 'sm' ? '≥576px' : 
      breakpoint === 'md' ? '≥768px' : 
      breakpoint === 'lg' ? '≥992px' : 
      breakpoint === 'xl' ? '≥1200px' : '≥1400px'})
  </Alert>
);

const ShadowTestPage: FC = (): JSX.Element => {
  const breakpoints = ['', 'sm', 'md', 'lg', 'xl', 'xxl'];
  const sizes = ['', '1', '2', '3', 'none'];
  const states = ['', 'hover', 'focus'];

  return (
    <Container fluid className="p-4 bg-light">
      <h1 className="h2 mb-4">Shadow Utilities Test Page</h1>
      
      <Tabs defaultActiveKey="regular" className="mb-4">
        {/* Regular Shadows */}
        <Tab eventKey="regular" title="Regular">
          <Card className="mb-4">
            <Card.Header>
              <Card.Title>Base Shadow Classes</Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="d-flex flex-wrap">
                {sizes.map(size => (
                  <ShadowBox 
                    key={size}
                    className={`shadow${size ? `-${size}` : ''}`}
                    label={`shadow${size ? `-${size}` : ''}`}
                  />
                ))}
              </div>
            </Card.Body>
          </Card>
        </Tab>

        {/* Responsive Shadows */}
        <Tab eventKey="responsive" title="Responsive">
          {breakpoints.filter(bp => bp).map(breakpoint => (
            <Card key={breakpoint} className="mb-4">
              <Card.Header>
                <Card.Title>{breakpoint.toUpperCase()} Breakpoint Classes</Card.Title>
              </Card.Header>
              <Card.Body>
                <ResponsiveNote breakpoint={breakpoint} />
                <div className="d-flex flex-wrap">
                  {sizes.map(size => (
                    <ShadowBox 
                      key={`${breakpoint}-${size}`}
                      className={`shadow-${breakpoint}-${size || '2'}`}
                      label={`shadow-${breakpoint}-${size || '2'}`}
                    />
                  ))}
                </div>
              </Card.Body>
            </Card>
          ))}
        </Tab>

        {/* States */}
        <Tab eventKey="states" title="States">
          {states.filter(state => state).map(state => (
            <Card key={state} className="mb-4">
              <Card.Header>
                <Card.Title>
                  {state.charAt(0).toUpperCase() + state.slice(1)} States
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="d-flex flex-wrap">
                  {sizes.map(size => (
                    <ShadowBox 
                      key={`${state}-${size}`}
                      className={`shadow${size ? `-${size}` : ''}-${state}`}
                      label={`shadow${size ? `-${size}` : ''}-${state}`}
                    />
                  ))}
                </div>
              </Card.Body>
            </Card>
          ))}
        </Tab>
      </Tabs>

      <Card>
        <Card.Header>
          <Card.Title>Shadow Values Reference</Card.Title>
        </Card.Header>
        <Card.Body>
          <ul className="list-unstyled">
            <li><code>shadow</code> or <code>shadow-2</code>: Regular shadow (0 .5rem 1rem rgba(0,0,0,.15))</li>
            <li><code>shadow-1</code>: Small shadow (0 .125rem .25rem rgba(0,0,0,.075))</li>
            <li><code>shadow-3</code>: Large shadow (0 1rem 3rem rgba(0,0,0,.175))</li>
            <li><code>shadow-none</code>: No shadow</li>
          </ul>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ShadowTestPage;