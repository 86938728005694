import { useAtom } from "jotai";
import { apiServerName } from "../Data/ApiUrl";
import { redirectAtom } from "../Data/Atoms";

export function BootstrapBreakpointDebugger() {
    const [redirect, setRedirect] = useAtom( redirectAtom );
    return <div className="d-none d-sm-flex" style={{
        color:        "black",
        fontFamily:   "monospace",
        fontSize:     "0.75em",
        // background:   'rgba(247, 201, 241, 0.25)',
        background:   "yellow",
        padding:      '.5rem     1rem',
        borderRadius: '10px',
    }}>
        <div className="d-block d-sm-none">            xs  {apiServerName}</div>
        <div className="d-none d-sm-block d-md-none">  sm  {apiServerName}</div>
        <div className="d-none d-md-block d-lg-none">  md  {apiServerName}</div>
        <div className="d-none d-lg-block d-xl-none">  lg  {apiServerName}</div>
        <div className="d-none d-xl-block d-xxl-none" >xl  {apiServerName}</div>
        <div className="d-none d-xxl-block" >          xxl {apiServerName}</div>
        {redirect !== undefined && redirect}
    </div>
}