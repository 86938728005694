import { Container, Row, Col, ListGroup, ListGroupItem, Badge, Accordion, AccordionItem } from "react-bootstrap";
import { PageTitle } from "./Controls/PageTitle";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Facility, ListedPlan, SubscribeUpdateReq, SubscribeUpdateInitResp, Subscription, SubscriptionEntry, Vehicle, AccessPeriodInfo } from "./Data/ApiTypes";
import { useEffect, useState } from "react";
import { Api } from "./Data/Api";
import { processResult } from "./Data/Result";
import { parseFacility } from "./Data/ApiParse";
import { LocalDate } from "@js-joda/core";
import { MonthlyParkingModify } from "./Controls/MonthlyParkingModify";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "./Data/ApiStripe";
import { StripeMonthlyUpdateCheckoutForm } from "./StripeMonthlyUpdateCheckoutForm";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { appearanceOptions, stripeFonts } from "./Controls/StripeAppearance";
import { Button } from "./Controls/Button";
import { pluralize } from "./Data/English";
import { Money, showMoney } from "./Data/Money";
import { ConfirmModal } from "./Controls/ConfirmModal";

export function UserPlanDetailsChangePage() {
    const nav = useNavigate();
    const [ facility,       setFacility       ] = useState<Facility>();
    const [ oldMonthlyPlan, setOldMonthlyPlan ] = useState<ListedPlan>();
    const [ selMonthlyPlan, setSelMonthlyPlan ] = useState<ListedPlan>();
    const [ readyToPay,     setReadyToPay     ] = useState( false );
    const [ resp,           setResp           ] = useState<SubscribeUpdateInitResp>();
    const [ sub,            vehicles          ] = useLoaderData() as [Subscription, Vehicle[]];
    const [ modSub,         setModSub         ] = useState<SubscriptionEntry>( { ...sub.currentEntry } );
    const [ paymentVis,     setPaymentVis     ] = useState<"hidden" | "visible">( "hidden" );
    const [ confirmSave,    setConfirmSave    ] = useState( false );
    const [ confirmDefer,   setConfirmDefer   ] = useState( false );
    const [ terms,          setTerms          ] = useState<string>();
    const [ proratedFee,    setProratedFee    ] = useState<Money>();    
    const isActive = ( ap: AccessPeriodInfo ) => {        
        const now = LocalDate.now();
        const startOk = ap.start.isEqual( now ) || ap.start.isBefore( now );
        const endOk   = now.isBefore( ap.end );
        return startOk && endOk;
    }
    const currentQuantity = sub.accessPeriods.filter( isActive ).map( ap => ap.quantity ).reduce( (a, b) => a + b, 0 );
    const plan = sub.currentEntry.plan;

    //some pre-computing
    const spacesAdded  = Math.max( 0, modSub.quantity - currentQuantity );
    const disabled     = modSub.quantity === sub.currentEntry.quantity;
    const showSave     = spacesAdded === 0;
    const showContinue = spacesAdded >   0 && !readyToPay;
    const showDefer    = spacesAdded >   0 && sub.currentEntry.isAccountsReceivable && !readyToPay;
    const cls          = `w-100 ${ disabled ? "border-secondary text-muted" : "" }`;

    useEffect( () => {
        const getFacilityDetails = async () => {
            const res = await Api.facilityDetails( sub.facility.facilityId );
            processResult( res,
                val => {
                    const f = parseFacility( val );
                    setFacility( f );
                    setOldMonthlyPlan( f.listedPlans.find( lp => lp.planEntryId === plan.planId ) );
                    setSelMonthlyPlan( f.listedPlans.find( lp => lp.planEntryId === plan.planId ) );
                },
                err => console.log( `Problem retrieving facility: ${err}`) ); //ignore for now
        }
        const getTerms = async () => {
            const res = await Api.planTermsGet( sub.currentEntry.plan.planId );
            processResult( res,
                val => setTerms( val ),
                err => console.log( `Problem retrieving terms: ${err}`) ); //ignore for now
        }
        getFacilityDetails();
        getTerms();
    }, [] );

    useEffect( () => {
        async function subscribePreview() {
            const res = await Api.subscriptionPreview( selMonthlyPlan!.planId, { start: LocalDate.now()!, planEntryId: selMonthlyPlan!.planEntryId, quantity: spacesAdded! } );
            if( res.isOk ) {
                setProratedFee( res.value.price );
            }
        }
        if( !selMonthlyPlan )
            return;
        subscribePreview();
    }, [selMonthlyPlan, modSub.quantity] );

    useEffect( () => {
        if( readyToPay ) {
            const updateReq: SubscribeUpdateReq = {
                facilityId:     facility!.facilityId,
                subscriptionId: sub.subscriptionId,
                newPlanEntryId: selMonthlyPlan?.planEntryId!,
                newQuantity:    modSub.quantity,
            };
            Api.subscriptionUpdateInit( updateReq ).then(
                res => processResult( res,
                    val => {
                        setResp( val );
                        setPaymentVis( "visible" );
                    },
                    err => console.error( err ) ) );
        }
    }, [readyToPay] );

    function saveChanges(): void {
        const updateReq: SubscribeUpdateReq = {
            facilityId:     facility!.facilityId,
            subscriptionId: sub.subscriptionId,
            newPlanEntryId: selMonthlyPlan?.planEntryId!,
            newQuantity:    modSub.quantity,
        };
        Api.subscriptionUpdateDecr( updateReq ).then(
            res => processResult( res,
                val => {
                    nav( `/user/plan/${sub.subscriptionId}` );
                },
                err => console.error( err ) ) );
    }

    function billMe(): void {
        const updateReq: SubscribeUpdateReq = {
            facilityId:     facility!.facilityId,
            subscriptionId: sub.subscriptionId,
            newPlanEntryId: selMonthlyPlan?.planEntryId!,
            newQuantity:    modSub.quantity,
        };
        Api.subscriptionUpdateDefer( updateReq ).then(
            res => processResult( res,
                val => {
                    nav( `/user/order/${val.orderId}` );
                },
                err => console.error( err ) ) );
    }

    const options: StripeElementsOptions = {
        clientSecret:                resp?.paymentIntentClientSecret!,
        customerSessionClientSecret: resp?.customerSessionClientSecret!,
        appearance:                  appearanceOptions,
        fonts:                       stripeFonts
    };

    if( !facility || !selMonthlyPlan || !oldMonthlyPlan || !proratedFee ) {
        return <></>;
    }

    return <Container fluid className="pb-3">
        <ConfirmModal title={"Confirm Spot Reduction"}
                      message={<div className="d-flex flex-column gap-3">
                          <div>Are you sure you want to reduce your parking to {modSub.quantity} spot?</div>
                          <div>The change will take effect on the next
                              next {sub.currentEntry.isAccountsReceivable ? "billing" : "processing"} date.
                          </div>
                      </div>}
                      show={confirmSave}
                      setShow={setConfirmSave}
                      confirmText="Save Changes"
                      cancelText="Cancel"
                      onConfirm={ () => saveChanges() } />

        <ConfirmModal title={"Confirm Invoice"}
                      message={<div className="d-flex flex-column gap-3">
                          <div>
                              Do you agree to pay for {spacesAdded} {pluralize( spacesAdded, "spot", "spots" ) }?</div>
                          <div>
                              An invoice will be generated immediately and is subject to the below terms and conditions
                          </div>
                          <Accordion>
                            <AccordionItem eventKey={"terms"}>
                                <Accordion.Header>
                                    Terms and Conditions
                                </Accordion.Header>
                                <Accordion.Body style={{ height: "16em", overflowY: "scroll" }}>
                                    <pre>
                                        {terms?.trim() === "" ? "No terms have been set" : terms}
                                    </pre>
                                </Accordion.Body>
                            </AccordionItem>
                          </Accordion>
                      </div>}
                      show={confirmDefer}
                      setShow={setConfirmDefer}
                      confirmText={`Invoice Me for ${showMoney( proratedFee! )}`}
                      cancelText="Cancel"
                      onConfirm={ () => billMe() } />

        <Row>
            <Col>
                <PageTitle>
                    Modify Plan at {facility.name}
                </PageTitle>
                <Container className="g-0">
                    <Row>
                        <Col xl="12">
                            <MonthlyParkingModify
                                selOpt={selMonthlyPlan}
                                start={LocalDate.now()}
                                disabled={readyToPay}
                                originalQuantity={currentQuantity}
                                quantity={modSub.quantity}
                                proratedFee={proratedFee}
                                setQuantity={ n => setModSub( { ...modSub, quantity: n } ) } />
                            <div className="mt-2 d-flex justify-content-end gap-1 text-end" style={{ display: "grid", gridAutoColumns: "1fr" }}>
                                {!readyToPay && <Button className="w-100"
                                        onClick={ () => nav( `/user/plan/${sub.subscriptionId}` ) }>
                                    Cancel
                                </Button>}
                                {showDefer && <Button className={cls}
                                                      disabled={disabled}
                                                      onClick={ () => setConfirmDefer( true ) }>
                                    Invoice Me
                                </Button>}
                                {showSave && <Button className={cls}
                                                     disabled={disabled}
                                                     onClick={ () => setConfirmSave( true ) }>
                                    Save Changes
                                </Button>}
                                {showContinue && <Button className={`${cls}`}
                                                         disabled={disabled}
                                                         onClick={ () => setReadyToPay( true )}>
                                    Continue <br />
                                    to Payment
                                </Button>}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <div style={{ visibility: paymentVis }}>
                                {resp && <Elements stripe={stripePromise} options={options}>
                                    <StripeMonthlyUpdateCheckoutForm init={resp} />
                                </Elements>}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    </Container>;
}
