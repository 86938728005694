import { atomWithStorage } from "jotai/utils"
import { DateTimeFormatter, LocalDate, LocalDateTime, LocalTime, ZonedDateTime } from "@js-joda/core";
import { atom } from "jotai";
import { Facility, Parker, Vehicle, ListedPlan } from "./ApiTypes";
import { nearest30, showBrowserDate, showBrowserTime } from "./Date";
import { parseParker } from "./ApiParse";
import { ParkerApi } from "./ApiTransport";
import { emitParker } from "./ApiEmit";

export enum SearchActivity {
    Hourly  = "hourly",
    Monthly = "monthly"
}

export interface HourlyForm {
    location:       string;
    enterAfterDate: string;
    enterAfterTime: string;
    exitBeforeDate: string;
    exitBeforeTime: string;
}

export interface MonthlyForm {
    location:  string;
    startDate: string;
}

export type SearchActivityType<T> = T extends SearchActivity.Hourly ? HourlyForm : MonthlyForm;

export const createHourlyForm = (): HourlyForm => {
    const now = LocalDateTime.now();
    const oneHourLater = now.plusHours( 3 );
    const ret = {
        location:       "",
        enterAfterDate: showBrowserDate( now.toLocalDate() ),
        enterAfterTime: showBrowserTime( nearest30( now ).toLocalTime() ),
        exitBeforeDate: showBrowserDate( oneHourLater.toLocalDate() ),
        exitBeforeTime: showBrowserTime( nearest30( oneHourLater ).toLocalTime() )
    };
    return ret;
};

export const createMonthlyForm = (): MonthlyForm => ( {
    location:  "",
    startDate: LocalDateTime.now().format( DateTimeFormatter.ofPattern( "uuuu-MM-dd" ) ),
} );

export interface HourlyCheckout {
    facility: Facility;
    start:    LocalDateTime;
    end:      LocalDateTime;
    vehicles: Vehicle[];
}

export interface MonthlyCheckout {
    facility:    Facility;
    option:      ListedPlan;
    start:       LocalDate;
    quantity:    number;
    vehicles:    Vehicle[];
}

export interface SpotSyncAuth {
    isLoggedIn:  boolean;
    account?:    Parker;
}

export interface SpotSyncAuthApi {
    isLoggedIn:  boolean;
    account?:    ParkerApi;
}

export interface PageMaskOpts {
    showMask: boolean;
    header:   React.ReactNode;
    subText?: string;
}

export const tabAtom             = atomWithStorage<SearchActivity>( "tab", SearchActivity.Hourly, undefined, { getOnInit: true } );
export const hourlyFormAtom      = atom<HourlyForm>(  createHourlyForm()  );
export const monthlyFormAtom     = atom<MonthlyForm>( createMonthlyForm() );
export const authAtomImpl        = atomWithStorage<SpotSyncAuthApi>( "auth", { isLoggedIn: false }, undefined, { getOnInit: true } );
export const authAtom = atom(
    ( get ): SpotSyncAuth => {
        const auth    = get( authAtomImpl );
        const isValid = auth.isLoggedIn && auth.account !== undefined;
        if( isValid ) {
            return { isLoggedIn: true, account: parseParker( auth.account! ) }
        }
        return { isLoggedIn: false };
    }, 
    ( _get, set, auth: SpotSyncAuth ) => {
        const isValid = auth.isLoggedIn && auth.account !== undefined;
        const parker = isValid ? emitParker( auth.account! ): undefined;
        set( authAtomImpl, { isLoggedIn: isValid, account: parker } );
    } );

export const hourlyCheckoutAtom  = atom<HourlyCheckout  | undefined>( undefined );
export const monthlyCheckoutAtom = atom<MonthlyCheckout | undefined>( undefined );
export const redirectAtom        = atomWithStorage<string | undefined>('redirectPath', undefined, undefined, { getOnInit: true } );
export const btnLoadingAtom      = atom(false);
export const maskAtom            = atom<PageMaskOpts | undefined>(undefined);
// export const [reservations, refreshReservations] = createFetchAtom<ReservationApi[]>( ApiUrl.reservationList(), [] );