import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { Navigate, NavLink, Outlet, useLoaderData, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { authAtom, maskAtom, redirectAtom } from "./Data/Atoms";
import { Badge, Col, Container, ListGroup, Row } from "react-bootstrap";
import { ArrowUpCircle } from "react-bootstrap-icons";
import { PageTitle } from "./Controls/PageTitle";
import { ReactNode } from "react";
import { cents, Money, showMoneyShort, toCents } from "./Data/Money";
import { FacilityParkerBalance } from "./Data/ApiTypes";
import { add, equal } from "dinero.js";

export interface UserPage {
    children: ReactNode;
    url:      string;
}

export interface MenuItemProps extends UserPage {
    onClick?:   () => void;
    className?: string;
    end?:       boolean;
    active?:    boolean;
}

export function MenuItem( props: MenuItemProps ) {
    const {
        children,
        url,
        active = false,
        end = false,
        className = `${active ? "my-active" : ""}`,
        onClick = () => 0
    } = props;
    return <ListGroup.Item key={url}
                           as={NavLink}
                           to={url}     //props on NavLink
                           end={end}    //props on NavLink
                           className={className}
                           onClick={onClick}>
        {children}
    </ListGroup.Item>;
}

export function OrdersMenuBadge( { balance }: { balance: Money } ) {
    const zero = cents( 0 );
    const isPaid = equal( balance, zero );
    return <Badge className="border border-primary bg-white fw-normal text-primary">
        {isPaid ? "Paid" : `${showMoneyShort( balance )} Due`}
    </Badge>;
}

export function AppUser() {
    const nav  = useNavigate();
    const loc  = useLocation();
    const setMask = useSetAtom( maskAtom );
    const [searchParams, setSearchParams] = useSearchParams();
    const [redirect, setRedirect] = useAtom( redirectAtom );
    const auth = useAtomValue( authAtom );
    const balances = useLoaderData() as FacilityParkerBalance[];
    const totalDebt = balances.map( b => b.balance ).reduce( (a, b) => add( a, b ), cents( 0 ) );

    //on loading the whole App
    //look at the url and see if there is a code
    //if there is a code, fetch to see if it is valid
    //if it is valid
    //when do email links include the password reset code?
    //should it do it regardless of whether the parker has never signed in?
    //http://parkops.spotsync.com/?code=2348923047283407823408
    //http://parkops.spotsync.com/user/5/invoice/1?code=2348923047283407823408

    if( !auth.isLoggedIn ) {
        setRedirect( loc.pathname );
        const redirectToResetCode: string | null = searchParams.get( "code" );
        if( redirectToResetCode ) {            
            return <Navigate to={`/reset?code=${redirectToResetCode}`} />;
        }
        return <Navigate to="/login" />;
    }

    //some stuff for handling mobile and
    //desktop versions of these pages
    //be careful modifying this, it is tricky
    const menuClassName    = loc.pathname === "/user"     ? ""       : "d-none";
    const contentClassName = loc.pathname === "/user"     ? "d-none" : "";
    const activeOverride   = loc.pathname === ( `/user` ) ? true     : false;

    return <Container className="pt-2">
        <Row>
            <Col md={4} className={`${menuClassName} d-md-block`}>
                <PageTitle>
                    Account
                </PageTitle>
                <ListGroup>
                    <MenuItem url="/user/profile" active={activeOverride}>  Overview     </MenuItem>
                    <MenuItem url="/user/reservation/upcoming">             Reservations </MenuItem>
                    <MenuItem url="/user/plan">                             Plans        </MenuItem>
                    <MenuItem url="/user/order" className="d-flex align-items-center gap-1">
                        Orders {toCents( totalDebt ) > 0 && <OrdersMenuBadge balance={totalDebt} />}
                    </MenuItem>
                    <MenuItem url="/user/payment" onClick={ () => setMask( { header: "Loading Payments", showMask: true, subText: "Please wait..." } )}>                          Payments     </MenuItem>
                    <MenuItem url="/user/vehicle">                          Vehicles     </MenuItem>
                    <MenuItem url="/user/billing">                          Billing      </MenuItem>
                    {/* <MenuItem url="/user/advanced">                         Advanced     </MenuItem> */}
                </ListGroup>
            </Col>
           <Col md={8} className={`${contentClassName} p-0 m-0 d-md-block`}>
                <div className="d-md-none w-100 p-3 d-flex flex-row align-items-center justify-content-center">
                    <NavLink to="">
                        <ArrowUpCircle className="me-1" />Return to My Account
                    </NavLink>
                </div>
                <div className="mx-2">
                    <Outlet />
                </div>
            </Col>
        </Row>
    </Container>;
}