// Third-party imports
import { useAtom }                                        from "jotai";
import { FieldValues, useForm, UseFormGetValues, UseFormReset }                                        from "react-hook-form";
import { ReactNode, useEffect, useState }                 from "react";
import { useLoaderData, useNavigate }                         from "react-router-dom";
import { InfoWindow, Map, Marker, useMap }                        from '@vis.gl/react-google-maps';
import { CloseButton, Col, Container, Row, Tab, Tabs }    from "react-bootstrap";

// App imports
import { Amenity }                  from "./Data/Amenity";
import { Button }                   from "./Controls/Button";
import { AmenitiesModal }           from "./Controls/AmenitiesModal";
import { AmenitiesButton }          from "./Controls/AmenitiesButton";
import { HourlySearch }             from "./Controls/HourlySearch";
import { MonthlySearch }            from "./Controls/MonthlySearch";
import { RatesModal }               from "./Controls/RatesModal";
import { ResultHourlyEntry }        from "./Controls/ResultHourlyEntry";
import { ResultMonthlyEntry }       from "./Controls/ResultMonthlyEntry";
import { FacilityHourlyDetails }    from "./Controls/FacilityHourlyDetails";
import { FacilityPlanDetails }   from "./Controls/FacilityPlanDetails";
import { createLocalDate, createLocalDateTime } from "./Data/Date";
import { HourlyForm, MonthlyForm, SearchActivity, createHourlyForm, createMonthlyForm,
         hourlyFormAtom, monthlyFormAtom, tabAtom } from "./Data/Atoms";
import { cents, max, min, showMoneyShort } from "./Data/Money";
import { computeCharge } from "./Data/Rates";
import { PersonWalking } from "react-bootstrap-icons";
import { LocalDateTime } from "@js-joda/core";
import { Facility, RateProgram } from "./Data/ApiTypes";
import { useOptionalParamIds } from "./Data/Common";
import React from "react";

interface MapHandlerProps {
    place: google.maps.places.PlaceResult | null;
}

const MapHandler = ( { place }: MapHandlerProps ) => {
    const map = useMap();
    useEffect( () => {
        if( !map || !place ) {
            return;
        }
        if( place.geometry?.viewport ) {
            map.fitBounds( place.geometry.viewport );
        }
    }, [map, place] );
    return null;
};

interface FacilityDetailsColProps {
    children:            ReactNode;
    setSelectedFacility: () => void;
}

const FacilityDetailsCol = ( props: FacilityDetailsColProps ) => {
    const { setSelectedFacility, children } = { ...props };
    return <Col md={7} lg={6} xl={5} xxl={4}
                className="bg-white fullheight p-3 scroll-y"
                style={{ position: "relative" }}>
        <div>
            <CloseButton className="shadow p-3 bg-white rounded-5 m-1 fs-4"
                style={{ position: "absolute", top: "3%", right: "5%", opacity: 1.0 }}
                onClick={() => setSelectedFacility()} />
        </div>
        <div>
            {children}
        </div>
    </Col>;
}

export interface ResultPageMode<TSearchForm extends FieldValues> {
    createForm:   () => TSearchForm; //for resetting the form
    searchFilter: ( searchForm: TSearchForm, facility: Facility ) => boolean;
    setForm:      ( searchForm: TSearchForm ) => void;
    resetForm:    UseFormReset<TSearchForm>;
}

function getPriceForMapPin( tab: SearchActivity, f: Facility, startDateTime: LocalDateTime, endDateTime: LocalDateTime ) {
    let price = cents( 0 );
    if( tab === SearchActivity.Hourly ) {
        price = computeCharge( f.hourlyRates[0], startDateTime, endDateTime );
    }
    if( tab === SearchActivity.Monthly ) {
        const prices = f.listedPlans.map( listedPlan => listedPlan.price );
        const [minPrice, maxPrice] = prices.length > 0 ? [min( prices ), max( prices )] : [cents(0), cents(0)];
        price = minPrice;
    }
    return price;
}

interface SearchActivityFuncs<TForm extends FieldValues> {
    createForm:   () => TForm;
    searchForm:   TForm;
    searchFilter: ( searchForm: TForm, facility: Facility ) => boolean;
    setForm:      ( searchForm: TForm ) => void;
    resetForm:    UseFormReset<TForm>;
    getValues:    UseFormGetValues<TForm>;
    getEntry:     ( facility:         Facility ) => JSX.Element;
    getDetails:   ( selectedFacility: Facility ) => JSX.Element;
}

export function ResultsPage() {
    //router stuff
    const results          = useLoaderData() as Facility[]; //from the loader
    const nav              = useNavigate();
    const { facilityId }   = useOptionalParamIds();

    const [place,        setPlace       ] = useState<google.maps.places.PlaceResult | null>( null );
    const [showRates,    setShowRates   ] = useState<RateProgram[]>();
    const [showFilters,  setShowFilters ] = useState( false );

    //set to true to search on page load
    const [requiredAmenities, setReqAmenities] = useState<Amenity[]>(  [] );

    const [tab,     setTab]     = useAtom( tabAtom         );
    const [hourly,  setHourly]  = useAtom( hourlyFormAtom  );
    const [monthly, setMonthly] = useAtom( monthlyFormAtom );

    const { register: regHr, watch: watchHr, getValues: getValuesHr, reset: resetHr, formState: formStateHr, setValue: setValueHr } = useForm<HourlyForm>(  { defaultValues: hourly  } );
    const { register: regMo, watch: watchMo, getValues: getValuesMo, reset: resetMo, formState: formStateMo, setValue: setValueMo } = useForm<MonthlyForm>( { defaultValues: monthly } );

    const startDateTime = createLocalDateTime( hourly.enterAfterDate, hourly.enterAfterTime );
    const endDateTime   = createLocalDateTime( hourly.exitBeforeDate, hourly.exitBeforeTime );
    const startDate     = createLocalDate( monthly.startDate );

    function hourlySearchFilter( form: HourlyForm, facility: Facility ) {
        return facility.hourlyRates.length > 0;
    }

    function monthlySearchFilter( form: MonthlyForm, facility: Facility ) {
        return facility.listedPlans.length > 0;
    }

    function getHourlyEntry( f: Facility ) {
        return <ResultHourlyEntry
            key={f.facilityId}
            facility={f}
            onClick={ () => selectFacility( f.facilityId ) }
            onRatesClick={ () => setShowRates( f.hourlyRates ) }
            start={startDateTime}
            end={endDateTime}
            className={ selectedFacility === f ? "border-primary" : "" }
        />;
    }

    function getMonthlyEntry( f: Facility ) {
        return <ResultMonthlyEntry
            key={f.facilityId}
            facility={f}
            start={startDate}
            onClick={ () => selectFacility( f.facilityId ) }
            className={ selectedFacility === f ? "border-primary" : "" }
        />
    }

    function getHourlyDetails( selectedFacility: Facility ) {
        return <FacilityDetailsCol setSelectedFacility={ () => nav( -1 ) }>
            <FacilityHourlyDetails
                facility={selectedFacility}
                start={startDateTime}
                end={endDateTime} />
        </FacilityDetailsCol>;
    }

    function getMonthlyDetails( selectedFacility: Facility ) {
        return <FacilityDetailsCol setSelectedFacility={ () => nav( -1 ) }>
            <FacilityPlanDetails facility={selectedFacility} start={startDate} />
        </FacilityDetailsCol>;
    }

    function getResultsImpl<T extends HourlyForm | MonthlyForm>( tab: SearchActivity ): SearchActivityFuncs<T> {
        if( tab === SearchActivity.Hourly ) {
            const hourlyArr: SearchActivityFuncs<HourlyForm> = {
                createForm:   createHourlyForm,
                searchForm:   hourly,
                searchFilter: hourlySearchFilter,
                resetForm:    resetHr,
                setForm:      setHourly,
                getValues:    getValuesHr,
                getEntry:     getHourlyEntry,
                getDetails:   getHourlyDetails
            }
            return hourlyArr as any;
        }
        const monthlyArr: SearchActivityFuncs<MonthlyForm> = {
            createForm:   createMonthlyForm,
            searchForm:   monthly,
            searchFilter: monthlySearchFilter,
            resetForm:    resetMo,
            setForm:      setMonthly,
            getValues:    getValuesMo,
            getEntry:     getMonthlyEntry,
            getDetails:   getMonthlyDetails
        }
        return monthlyArr as any;
    }

    const {
        createForm,
        searchForm,
        searchFilter,
        setForm,
        resetForm,
        getValues,
        getEntry,
        getDetails
    } = getResultsImpl( tab );

    function selectFacility( id: number | undefined ) {
        if( id === undefined ) {
            nav( "/results" );
            return;
        }
        //the bang bang !! used for the replace is a clever trick for implementing
        //that if someone presses on a facility to view details
        //and then clicks on another facility, they won't both be
        //pushed to the top history stack, we will replace the
        //current page
        nav( `/results/${id}`, { replace: !!facilityId } );
    }

    function reset() {
        setForm(   { ...createForm(), location: getValues("location") } );
        resetForm( { ...createForm(), location: getValues("location") } );
    }

    function submit() {
        setForm( getValues() );
        return;
    }

    // function amenityFilter( f: Facility ) {
    //     for( let a of requiredAmenities ) {
    //         if( f.amenities.findIndex( el => el === a ) === -1 ) {
    //             return false;
    //         }
    //     }
    //     return true;
    // }

    const selectedFacility = facilityId ? results.find( f => f.facilityId === facilityId ) : undefined;
    let facilityDetails = <></>;
    if( selectedFacility ) {
        facilityDetails = getDetails( selectedFacility );
    }

    const hideListClassNames  = selectedFacility ? "d-none" : "";
    const filteredFacilities  = results/*.filter( amenityFilter )*/.filter( f => searchFilter( searchForm, f ) );
    const searchResultEntries = filteredFacilities.map( f => getEntry( f ) );

    return <div className="fullheight">

        <AmenitiesModal show={showFilters}
                        setShow={setShowFilters}
                        amenities={requiredAmenities}
                        setAmenities={setReqAmenities} />

        <RatesModal show={showRates}
                    setShow={setShowRates} />

        <Container fluid className="fullheight">
            <Row className="fullheight justify-content-start">
                <Col className={`${hideListClassNames} d-xl-block bg-white scroll-y fullheight pt-2`} md={7} lg={6} xl={5} xxl={4}>
                    <Tabs defaultActiveKey="hourly"
                          activeKey={tab}
                          onSelect={ k => setTab( k as SearchActivity )}
                          className="mb-3 w-100"
                          variant="underline"
                          justify>
                        <Tab eventKey="hourly"
                             title="Hourly">
                            <HourlySearch
                                register={regHr}
                                watch={watchHr}
                                setValue={setValueHr}
                                errors={formStateHr.errors}
                                setPlace={setPlace} />
                        </Tab>
                        <Tab eventKey="monthly"
                             title="Monthly">
                            <MonthlySearch
                                register={regMo}
                                watch={watchMo}
                                setValue={setValueMo}
                                errors={formStateMo.errors}
                                setPlace={setPlace} />
                        </Tab>
                    </Tabs>
                    <div className="d-flex justify-content-center h-auto mb-3">
                        <Button className="me-2 w-100"
                                onClick={ reset } >
                            Today
                        </Button>
                        <AmenitiesButton className="me-2 w-100 text-black" style={{ visibility: "hidden" }}
                            onClick={ () => setShowFilters( true ) }
                            amenities={requiredAmenities} >
                                Amenities
                        </AmenitiesButton>
                        <Button className="w-100"
                                onClick={ submit }>
                            Search
                        </Button>
                    </div>
                    {searchResultEntries}
                </Col>
                {selectedFacility && facilityDetails}
                <Col className="d-none d-md-block">
                    <Map defaultCenter={{ lat: 39.299236, lng: -76.609383 }}
                         defaultZoom={13}
                         mapId="6d064567c51cdbc9">
                    {filteredFacilities.map( f => {
                        function clickFacility( ev: any ): void {
                            selectFacility( f.facilityId );
                        }
                        if( f === selectedFacility ) {
                            return <React.Fragment key={f.facilityId} />;
                        }
                        //TODO: need to migrate to AdvancedMarker
                        return <Marker key={f.facilityId}
                                       icon={{ url: "/img/map-pin-round.svg",
                                               labelOrigin: new google.maps.Point( 22, 18 ),
                                               scaledSize:  new google.maps.Size( 45, 45 ) }}
                                       position={f.coordinates}
                                       onClick={clickFacility}
                                       zIndex={ f.facilityId }
                                       label={{ fontSize: "1.4em",
                                                fontWeight: "900",
                                                color: "black",
                                                text: showMoneyShort( getPriceForMapPin( tab, f, startDateTime, endDateTime ) ) }}>
                        </Marker>
                    })}
                    {selectedFacility &&
                        <InfoWindow onClose={ () => selectFacility( undefined ) }
                                    zIndex={1000}
                                    headerDisabled
                                    position={selectedFacility.coordinates}>
                            <div className="p-1" style={{ display: "grid", gridTemplateColumns: "auto auto", gap: "0.5em" }}>
                                <div className="fw-bold fs-6 grid-col-1">
                                    {selectedFacility.name}
                                </div>
                                <div className="fw-bold fs-6 grid-col-2 text-end">
                                    {showMoneyShort( getPriceForMapPin( tab, selectedFacility, startDateTime, endDateTime ) )}
                                </div>
                                <div className="grid-col-1 d-flex flex-row align-items-center">
                                    {/* <PersonWalking /> {selectedFacility.walkTime} min */}
                                    <PersonWalking /> 5 min
                                </div>
                                <div />
                            </div>
                        </InfoWindow>}
                        <MapHandler place={place} />
                    </Map>
                </Col>
            </Row>
        </Container>
    </div>;
}

