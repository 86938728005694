import { Card, Col, Container, Row } from "react-bootstrap";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Payment           } from "./Data/ApiTypes";
import { PageTitle       } from "./Controls/PageTitle";
import { showMoney       } from "./Data/Money";
import { facilityImg     } from "./Data/Common";
import { showAddressHtml } from "./Data/Location";
import { showPaymentType   } from "./Data/Finance";
import { showInstant, showInstantDate, showInstantLocalDate     } from "./Data/Date";
import { useSetAtom } from "jotai";
import { maskAtom } from "./Data/Atoms";
import { useEffect } from "react";

export function UserPaymentsPage() {
    const nav  = useNavigate();
    const setMask = useSetAtom( maskAtom );
    const pmts = useLoaderData() as Payment[];

    useEffect( () => {
        setMask( undefined );
    }, [ pmts ] );

    return <Container fluid>
        <Row>
            <Col>
                <PageTitle>
                    Payments
                </PageTitle>
                {pmts.length === 0 && <>
                    You haven't made any payments
                </>}
                {pmts.map( pmt => {
                    const facility = pmt.facility;
                    return <Card key={pmt.paymentId} className="my-2"
                                 style={{ cursor: "pointer" }}
                                 onClick={() => nav(`/user/payment/${pmt.paymentId}`)}>
                        <Card.Header className="text-start fw-bold">
                            {showPaymentType( pmt.type )}
                        </Card.Header>
                        <Card.Body className="purchase-layout gap-2">
                            <div className="grid-area-a">
                                <img className="facility-result-pic"
                                    src={facilityImg( facility.listImageId )}
                                    style={{ aspectRatio: "1 / 1", objectFit: "cover" }} />
                            </div>
                            <div className="grid-area-b">
                                <div className="fw-bold">
                                    {facility.name}
                                    {/* {showAddressHtml( facility.address )} */}
                                </div>
                            </div>
                            <div className="grid-area-c position-relative" style={{ justifySelf: "end" }}>
                                <div className="fs-4 fw-bold">
                                    {showMoney( pmt.remaining )}
                                </div>
                                <div style={{ fontSize: "0.75em", position: "relative", top: "-5%", left: "0.05em" }} className="text-end">
                                    {showInstantDate( pmt.createdWhen, facility.timeZone )}
                                </div>
                            </div>
                            <div className="grid-area-d text-end d-flex gap-2">
                                {pmt.paymentMethod}
                            </div>
                        </Card.Body>
                    </Card>;
                } )}
            </Col>
        </Row>
    </Container>;
}