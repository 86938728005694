//css
import "./index.scss"
import "./index.css";

//react
import React    from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, LoaderFunction, redirect, RouterProvider } from "react-router-dom";

//pages and components
import { App                          } from "./App";
import { LandingPage                  } from "./LandingPage";
import { ResultsPage                  } from "./ResultsPage";
import { FacilityDetailsPage          } from "./FacilityDetailsPage";
import { CheckoutPageHourly           } from "./CheckoutPageHourly";
import { CheckoutPageMonthly          } from "./CheckoutPageMonthly";
import { AppUser                      } from "./AppUser";
import { UserProfilePage              } from "./UserProfilePage";
import { UserBillingPage              } from "./UserBillingPage";
import { UserVehiclesPage             } from "./UserVehiclesPage";
import { UserPlansPage                } from "./UserPlansPage";
import { UserAdvancedPage             } from "./UserAdvancedPage";
import { UserReservationsPage         } from "./UserReservationsPage";
import { SignUpPage                   } from "./SignUpPage";
import { LogInPage                    } from "./LogInPage";
import { ForgotPasswordPage           } from "./ForgotPasswordPage";
import { AboutPage                    } from "./AboutPage";
import { SandboxPage                  } from "./SandboxPage";
import { AtomDebugPage                } from "./AtomDebugPage";
import { UserReservationDetailsPage   } from "./UserReservationDetailsPage";
import { UserBillingDetailsPage       } from "./UserBillingDetailsPage";
import { UserBillingAddPage           } from "./UserBillingAddPage";
import { UserBillingAddSuccessPage } from "./UserBillingAddSuccessPage";
import { UserGroupPlansPage } from "./UserGroupPlansPage";
import { UserReservationReceiptPage } from "./UserReservationReceiptPage";
import { UserPlanDetailsPage } from "./UserPlanDetailsPage";
import { Api } from "./Data/Api";
import { loadResult } from "./Data/Result";
import { parseFacility, parseFacilityParkerBalance, parseInvoice, parseOperator, parseOrder, parseOrderDetails, parseParker, parsePayment, parseReservation, parseSettleInitResp, parseSubListEntry, parseSubscription } from "./Data/ApiParse";
import { Facility, Invoice, Order, OrderDetails, Payment, PaymentMethodResp, Reservation, SubListEntry, Subscription, SubscriptionGuest, Vehicle } from "./Data/ApiTypes";
import { UserPlanDetailsChangePage } from "./UserPlanDetailsChangePage";
import { UserPaidPage } from "./UserPlanPaidPage";
import { UserOrdersPage } from "./UserOrdersPage";
import { UserOrderDetailsPage } from "./UserOrderDetailsPage";
import { ResetPasswordPage } from "./ResetPasswordPage";
import { getDefaultStore } from "jotai";
import { authAtom, MonthlyCheckout, monthlyCheckoutAtom, redirectAtom } from "./Data/Atoms";
import { SettleInitReqApi } from "./Data/ApiTransport";
import { UserSettlePage } from "./UserSettlePage";
import { UserPaymentsPage } from "./UserPaymentsPage";
import { UserInvoicesPage } from "./UserInvoicesPage";
import { UserInvoiceDetailsPage } from "./UserInvoiceDetailsPage";
import { ErrorPage } from "./ErrorPage";
import { UserPaymentDetailsPage } from "./UserPaymentDetailsPage";
import { UserPlanDetailsGuestPage } from "./UserPlanDetailsGuestPage";
import { UserPlanUpdateBillingPage } from "./UserPlanUpdateBillingPage";
import { paramIds } from "./Data/Common";
import { LocalDate } from "@js-joda/core";
import { CheckoutPageMonthlyInvite } from "./CheckoutPageMonthlyInvite";
import ShadowTestPage from "./ShadowSandbox";

function identity<T>( val: T ) { return val; }

export const ldReset: LoaderFunction = async( { request, params } ) => {    
    const url   = new URL( request.url );
    const store = getDefaultStore();
    const code  = url.searchParams.get( "code" );

    //allow it to pass through
    if( code === null ) {
        console.log( "there was no reset code" );
        return null;
    }
    if( url.pathname === "/reset" ) {
        console.log( "we were on the reset page" );
        return null;
    }
    console.log( `storing redirect to ${url.pathname}` );
    url.searchParams.delete( "code" );
    store.set( redirectAtom, `${url.pathname}?${url.searchParams}` );
    return redirect( `/reset?code=${code}` );
}

const ldOperator: LoaderFunction<Facility> = async( { params } ) => {
    return Api.opDetails().then(
        res => loadResult(
            res,
            parseOperator,
            notLoggedInHandler
        ) );
}

const ldFacilities: LoaderFunction<Facility[]> = async( { params } ) => {
    return await Api.facilitySearch()
        .then( res => loadResult( res, res => res.map( parseFacility ), notLoggedInHandler ) );
}

const ldFacility: LoaderFunction<Facility> = async( { params } ) => {
    const { facilityId } = paramIds( params );
    return await Api.facilityDetails( facilityId )
        .then( res => loadResult( res, parseFacility, notLoggedInHandler ) );
}

function notLoggedInHandler( err: string ) {
    const url = new URL( window.location.href );
    const store = getDefaultStore();
    if( err === "You are not logged in" ) {
        store.set( authAtom, { isLoggedIn: false } );
        store.set( redirectAtom, `${url.pathname}?${url.searchParams}` );
        console.warn( err );
        return redirect( "/login" );
    }
    return err;
}

// loader function for rate routes
const ldVehicles: LoaderFunction<Vehicle[]> =
    async ( { params } ) => await Api.vehicleList()
        .then( res => loadResult( res,
            val => val,
            notLoggedInHandler ) );

const ldPaymentMethods: LoaderFunction<PaymentMethodResp[]> =
    async( { params } ) => await Api.stripePaymentMethodList()
        .then( res => loadResult( res,
            val => val,
            notLoggedInHandler ) );

const ldReservations: LoaderFunction<Reservation[]> =
    async( { params } ) => await Api.reservationList()
        .then( res => loadResult( res,
            val => val.map( parseReservation ),
            notLoggedInHandler ) );

const ldReservationDetails: LoaderFunction = async( { params } ) => {
    const { reservationId } = params;
    return await Api.reservationDetails( parseInt( reservationId! ) )
        .then( res => loadResult( res,
            parseReservation,
            notLoggedInHandler ) );
}

const ldSubs: LoaderFunction<SubListEntry[]> =
    async( { params } ) => await Api.subscriptionList()
        .then( res => loadResult( res, val => val.map( parseSubListEntry ), notLoggedInHandler ) );

const ldOrders: LoaderFunction<Order[]> = async( { params } ) => {
    return await Api.orderList()
        .then( res => loadResult( res,
            val => val.map( parseOrder ),
            notLoggedInHandler ) )
}

const ldOrderDetails: LoaderFunction<OrderDetails> = async( { params } ) => {
    const { orderId } = params;
    return await Api.orderDetails( parseInt( orderId! ) )
        .then( res => loadResult( res,
            val => parseOrderDetails( val ),
            notLoggedInHandler ) );
};

const ldInvoices: LoaderFunction<Invoice> = async( { params } ) => {
    return await Api.invoiceList()
        .then( res => loadResult( res,
            val => val.map( parseInvoice ),
            notLoggedInHandler ) );
};

const ldInvoiceDetails: LoaderFunction<Invoice> = async( { request, params } ) => {
    const { invoiceId } = params;
    const path  = new URL( request.url );
    const store = getDefaultStore();
    const resetCode = path.searchParams.get( "code" );
    if( resetCode ) {
        store.set( redirectAtom, path.pathname );
        return redirect( `/reset?code=${resetCode}` );
    }
    return await Api.invoiceDetails( parseInt( invoiceId! ) )
        .then( res => loadResult( res,
            parseInvoice,
            notLoggedInHandler ) );
}

const ldPayments: LoaderFunction<Payment[]> = async( { params } ) => {
    return await Api.paymentList()
        .then( res => loadResult( res,
            val => val.map( parsePayment ),
            notLoggedInHandler ) );
}

const ldPaymentDetails: LoaderFunction<Payment> = async( { params } ) => {
    const { paymentId } = params;
    return await Api.paymentDetails( parseInt( paymentId! ) )
        .then( res => loadResult( res,
            parsePayment,
            notLoggedInHandler ) );
}

const ldOrderDetailsForPay: LoaderFunction<OrderDetails> = async( { request, params } ) => {
    const { orderId } = params;
    const path  = new URL(request.url);
    const store = getDefaultStore();
    const resetCode = path.searchParams.get( "code" );
    if( resetCode ) {
        store.set( redirectAtom, path.pathname );
        return redirect( `/reset?code=${resetCode}` );
    }
    return await Api.orderDetails( parseInt( orderId! ) )
        .then( res => loadResult( res,
            parseOrderDetails,
            notLoggedInHandler ) );
};

const ldSettle: LoaderFunction<OrderDetails> = async( { request, params } ) => {    
    console.log( "loading settle" );
    const { facilityId } = params;
    const store = getDefaultStore();
    const url  = new URL(request.url);
    const resetCode = url.searchParams.get( "code" );
    if( resetCode ) {
        store.set( redirectAtom, url.pathname );
        return redirect( `/reset?code=${resetCode}` );
    }
    const init: SettleInitReqApi = { facilityId: parseInt( facilityId! ) };
    return Api.settleInit( init ).then(
        res => loadResult( res,
            parseSettleInitResp,
            notLoggedInHandler ) );
};

const ldResetCode: LoaderFunction = async( { request, params } ) => {
    const url = new URL( request.url );
    const store = getDefaultStore();
    const code = url.searchParams.get( "code" );
    return await Api.resetCodeCheck( { resetPasswordCode: code! } )
        .then( res => loadResult( res, parseParker,
            err => {                
                if( err === "Invalid or expired reset code" ) {
                    var desiredUrl = store.get( redirectAtom );
                    var auth = store.get( authAtom );
                    if( auth.isLoggedIn && desiredUrl !== undefined ) {
                        return redirect( desiredUrl );
                    }
                    //TODO: review, middleware will make this better/easier
                    const redir = store.get( redirectAtom ) ?? "/";
                    store.set( redirectAtom, undefined );
                    return redirect( redir );
                }
            } ) );
}

const ldSubDetails: LoaderFunction<[Subscription, Vehicle[]]> = async( { params } ) => {
    const { planId } = params;
    const subDetails = await Api.subscriptionDetails( parseInt( planId! ) )
        .then( res => loadResult( res,
            parseSubscription,
            notLoggedInHandler ) );

    const vehicles = await Api.vehicleList()
        .then( res => loadResult( res, val => val, notLoggedInHandler ) );

    return [subDetails, vehicles];
}

const ldSubGuestDetails: LoaderFunction<[SubscriptionGuest, Vehicle[]]> = async( { params } ) => {
    const { planId } = params;
    const subGuestDetails = await Api.subscriptionGuestDetails( parseInt( planId! ) )
        .then( res => loadResult( res, identity, notLoggedInHandler ) );

    const vehicles = await Api.vehicleList()
        .then( res => loadResult( res, val => val, notLoggedInHandler ) );

    return [subGuestDetails, vehicles];
}

const ldPaymentMethodDetails: LoaderFunction = async( { params } ) => {
    const { methodId } = params;
    return await Api.stripePaymentMethodGet( methodId! )
        .then( res => loadResult( res,
            val => val,
            notLoggedInHandler ) );
}

const ldBalances: LoaderFunction = async( { params } ) => {
    return await Api.facilityParkerBalanceList()
        .then( res => loadResult( res,
            val => val.map( parseFacilityParkerBalance ),
            notLoggedInHandler ) );
}

const ldMonthlyUrlImpl = async ( facilityId: number, planId: number ) => {
    return Api.facilityDetails( facilityId )
        .then( res => loadResult( res, val => {
            const f = parseFacility( val );
            const fnd = f.listedPlans.find( lp => lp.planId === planId );
            return {
                facility: f,
                option: fnd,
                quantity: 1,
                start: LocalDate.now(),
                vehicles: [],
            };
        }, err => err ) );
}

//this is for the QR code support! jeez this is getting complicated!
const ldMonthly: LoaderFunction<[MonthlyCheckout]> = async( args ) => {
    const ld = await ldReset( args );    
    if( ld !== null ) { 
        return ld;
    }
    const { request, params } = args;
    const url          = new URL( request.url );
    const searchParams = url.searchParams;
    const store  = getDefaultStore();
    const chkout = store.get( monthlyCheckoutAtom );    
    const facilityId = searchParams.get( "facilityId" );
    const planId     = searchParams.get( "planId"     );    
    if( facilityId !== null && planId !== null ) {
        return await ldMonthlyUrlImpl( parseInt( facilityId ), parseInt( planId ) );
    }
    if( chkout !== undefined ) {
        return chkout;
    }
    return redirect( "/" );
}

const ldMonthlyInvite: LoaderFunction<[MonthlyCheckout]> = async( args ) => {    
    const ld = await ldReset( args );    
    if( ld !== null ) { 
        return ld;
    }
    const { request, params } = args;
    const url          = new URL( request.url );
    const searchParams = url.searchParams;    
    const invite = searchParams.get( "invite" );    
    if( invite !== null ) {
        const ldInvite = await ldMonthlyInviteImpl( parseInt( invite ) );
        if( ldInvite !== undefined ) {
            return ldInvite;
        }
        //else
        const store = getDefaultStore();    
        const url = new URL( request.url );
        store.set( redirectAtom, `${url.pathname}?${url.searchParams}` );
        return redirect( "/login" );
    }
}

const ldMonthlyInviteImpl = async ( parkerPlanInviteId: number ) => {    
    return Api.planInviteGet( parkerPlanInviteId )
        .then( async res => {
            if( res.isOk ) {
                const inv = res.value;
                const facility = await Api.facilityDetails( inv.facilityId )
                    .then( res => loadResult( res, parseFacility, err => err ) );                    
                return [inv, facility];
            }            
            return undefined;
        } );
}

const router = createBrowserRouter( [ {
    path: "/", element: <App />, errorElement: <ErrorPage />, loader: ldReset, children: [
        //main parking stuff
        { path: "/",                                            element: <LandingPage             /> },
        { path: "/results",                                     element: <ResultsPage             />,
            loader: ldFacilities,
            shouldRevalidate: () => false,
            children: [ { path: "/results/:facilityId",         element: <ResultsPage /> }, ] },
        { path: "/checkout/hourly",                             element: <CheckoutPageHourly        /> },
        { path: "/checkout/monthly",                            element: <CheckoutPageMonthly       />, loader: ldMonthly  },
        { path: "/checkout/monthly/invite",                     element: <CheckoutPageMonthlyInvite />, loader: ldMonthlyInvite },
        { path: "/facility/:facilityId",                        element: <FacilityDetailsPage       />, loader: ldFacility },
        //user info
        { path: "/user",                                        element: <AppUser />, id: "/user", loader: ldBalances, children: [
            { path: "/user",                                    element: <UserProfilePage            /> },
            { path: "/user/profile",                            element: <UserProfilePage            /> }, //done

            //billing
            { path: "/user/billing",                            element: <UserBillingPage            />, loader: ldPaymentMethods }, //done
            { path: "/user/billing/:methodId",                  element: <UserBillingDetailsPage     />, loader: ldPaymentMethodDetails }, //done
            { path: "/user/billing/add",                        element: <UserBillingAddPage         /> },
            { path: "/user/billing/add/success",                element: <UserBillingAddSuccessPage  /> },
            { path: "/user/vehicle",                            element: <UserVehiclesPage           />, loader: ldVehicles     },

            //reservations
            { path: "/user/reservation",                        element: <UserReservationsPage       />, loader: ldReservations },
            { path: "/user/reservation/:time",                  element: <UserReservationsPage       />, loader: ldReservations },
            { path: "/user/reservation/details/:reservationId", element: <UserReservationDetailsPage />, loader: ldReservationDetails },
            { path: "/user/reservation/receipt/:reservationId", element: <UserReservationReceiptPage />, loader: ldReservationDetails },

            //finance and orders
            { path: "/user/invoice",                            element: <UserInvoicesPage           />, loader: ldInvoices       },
            { path: "/user/invoice/:invoiceId",                 element: <UserInvoiceDetailsPage     />, loader: ldInvoiceDetails },

            //payments
            { path: "/user/payment",                            element: <UserPaymentsPage           />, loader: ldPayments       },
            { path: "/user/payment/:paymentId",                 element: <UserPaymentDetailsPage     />, loader: ldPaymentDetails },

            //orders
            { path: "/user/order",                              element: <UserOrdersPage             />, loader: ldOrders         },
            { path: "/user/order/:orderId",                     element: <UserOrderDetailsPage       />, loader: ldOrderDetails   },

            //make payments
            { path: "/user/paid",                               element: <UserPaidPage               />, loader: undefined },
          //{ path: "/user/order/:orderId/pay",                 element: <UserOrderPayPage           />, loader: ldOrderDetailsForPay },
            { path: "/user/:facilityId/pay",                    element: <UserSettlePage             />, loader: ldSettle },

            //subscriptions
            { path: "/user/plan",                               element: <UserPlansPage              />, loader: ldSubs },
            { path: "/user/plan/:planId",                       element: <UserPlanDetailsPage        />, loader: ldSubDetails },
            { path: "/user/plan/:planId/setup",                 element: <UserPlanUpdateBillingPage  />, loader: ldSubDetails },
            { path: "/user/plan/:planId/guest",                 element: <UserPlanDetailsGuestPage   />, loader: ldSubGuestDetails },
            { path: "/user/plan/:planId/edit",                  element: <UserPlanDetailsChangePage  />, loader: ldSubDetails },

            //etc
            { path: "/user/group",                              element: <UserGroupPlansPage         /> },
            { path: "/user/advanced",                           element: <UserAdvancedPage           /> },
        ] },

        //user
        { path: "/signup",                                      element: <SignUpPage              /> },
        { path: "/login",                                       element: <LogInPage               /> },
        { path: "/forgot",                                      element: <ForgotPasswordPage      /> },
        { path: "/reset",                                       element: <ResetPasswordPage       />, loader: ldResetCode },
        { path: "/about",                                       element: <AboutPage               />, loader: ldOperator  },

        //debug
        { path: "/sandbox",                                     element: <SandboxPage             /> },
        { path: "/shadow",                                      element: <ShadowTestPage             /> },
        { path: "/atom",                                        element: <AtomDebugPage           /> },
        { path: "/framer",                                      element: <></> }
    ]
} ] );

const root = ReactDOM.createRoot(
    document.getElementById( "root" ) as HTMLElement
);

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
