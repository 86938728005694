export let apiServerName = "local";
const detectServ = ( url: string ) => {
    const loc = url;
    if( loc.includes( "connect.dev.spotsync.com" ) ) {
        apiServerName = "dev";
        return 'https://api.dev.spotsync.com/connect';
    }
    if( loc.includes( "parkops.spotsync.com" ) ) {
        apiServerName = "live";
        return 'https://api.spotsync.com/connect';
    }
    apiServerName = "local";
    return "https://local.dev:7063/connect";
}

const serv = detectServ( window.location.href );
const url = ( tail: string ) => { return `${serv}${tail}`; }

export class ApiUrl {
    //operator
    static opDetails() { return url( `/` ); }

    //parker
    static amILoggedIn()                              { return url( `/auth/`                             ); } //GET
    static signUp()                                   { return url( `/auth/signup`                       ); } //POST
    static logIn()                                    { return url( `/auth/login`                        ); } //POST
    static logOut()                                   { return url( `/auth/logout`                       ); } //POST
    static forgotPassword()                           { return url( `/auth/forgot`                       ); } //POST
    static resetCodeCheck()                           { return url( `/auth/check`                        ); } //POST
    static resetPassword()                            { return url( `/auth/reset`                        ); } //POST
    static changeName()                               { return url( `/auth/name`                         ); } //POST
    static changePhoneNumber()                        { return url( `/auth/phone`                        ); } //POST
    static changeEmailAddress()                       { return url( `/auth/email`                        ); } //POST
    static changePassword()                           { return url( `/auth/password`                     ); } //POST
    static deleteAccount()                            { return url( `/auth/delete`                       ); } //GET (retrieves a random secret)
    static deleteAccountConfirm()                     { return url( `/auth/delete-confirm`               ); } //POST

    //parker vehicle functionality
    static vehicleDetails( id: number )               { return url( `/vehicle/${id}`                     ); } //GET
    static vehicleAdd()                               { return url( `/vehicle/add`                       ); } //POST
    static vehicleList()                              { return url( `/vehicle/`                          ); } //GET
    static vehicleUpdate( id: number )                { return url( `/vehicle/${id}`                     ); } //POST
    static vehicleDelete( id: number )                { return url( `/vehicle/${id}/delete`              ); } //POST

    //user reservations
    static reservationList()                          { return url( `/reservation`                       ); } //GET
    static reservationDetails( id: number )           { return url( `/reservation/${id}`                 ); } //GET
    static reservationInit()                          { return url( `/reservation/init`                  ); } //POST
    static reservationConfirm()                       { return url( `/reservation/confirm`               ); } //POST
    static reservationCancel(  id: number )           { return url( `/reservation/${id}/cancel`          ); } //POST
    
    static subscriptionList()                         { return url( `/subscription`                      ); } //GET
    static subscriptionDetails( id: number )          { return url( `/subscription/${id}`                ); } //GET
    static subscriptionGuestDetails( id: number )     { return url( `/subscription/${id}/guest`          ); } //GET
    static subscriptionSetupPayment( id: number )     { return url( `/subscription/${id}/setup`          ); } //POST
    static subscribePreview( id: number )             { return url( `/plan/${id}/price`                  ); } //POST
    static subscribeInvitePreview( id: number )       { return url( `/plan/invite/price`                 ); } //POST
    static planTerms( id: number )                    { return url( `/plan/${id}/terms`                  ); } //GET
    static planInviteDetails( id: number )            { return url( `/plan/invite/${id}`                 ); } //GET

    //sub-parker management
    static subParkerCreate( subId: number ) { return url(`/subscription/${subId}/invite`);        } //POST
    static subParkerUpdate( subId: number ) { return url(`/subscription/${subId}/parker`);        } //POST
    static subParkerDelete( subId: number ) { return url(`/subscription/${subId}/parker/remove`); } //POST

    //subscription vehicle management
    static subscriptionVehicleAdd( subId: number )                       { return url(`/subscription/${subId}/vehicle/add`);         } //POST    
    static subscriptionVehicleDelete( subId: number, vehicleId: number ) { return url(`/subscription/${subId}/vehicle/${vehicleId}/delete`); } //POST

    //created during checkout
    static subscriptionInit()                         { return url( `/subscription/create/init`                  ); } //POST
    static subscriptionInviteInit()                   { return url( `/subscription/create/init/invite`           ); } //POST
    static subscriptionConfirm()                      { return url( `/subscription/create/confirm`               ); } //POST    

    //subscription update
    static subscriptionUpdateInit()                   { return url( `/subscription/update/init`                  ); } //POST
    static subscriptionUpdateDecr()                   { return url( `/subscription/update/decr`                  ); } //POST
    static subscriptionUpdateDefer()                  { return url( `/subscription/update/defer`                 ); } //POST
    static subscriptionPause( id: number )            { return url( `/subscription/${id}/pause`                  ); } //GET    
  //static subscriptionUpdateConfirm()                { return url( `/subscription/update/confirm`               ); } //POST //NOT IMPLEMENTED

    //facilities
    static facilitySearch()                           { return url( `/facility`                          ); } //POST
    static facilityDetails( id: number )              { return url( `/facility/${id}`                    ); } //GET

    //images
    static imageGet( imgId: number ) { return url( `/img/${imgId}` ); } //GET

    //finance
    static facilityParkerBalanceList()                { return url(`/finance/balance`);          } // POST
    static settleInit()                               { return url(`/finance/settle/init`);      } // POST
    static orderPayInit()                             { return url(`/finance/order/init`);       } // POST

    static orderList()                                { return url(`/finance/order`);            } // GET
    static orderDetails( id: number )                 { return url(`/finance/order/${id}`);      } // GET

    static invoiceList()                              { return url(`/finance/invoice`);          } // GET
    static invoiceDetails( id: number )               { return url(`/finance/invoice/${id}`);    } // GET

    static paymentList()                              { return url(`/finance/payment`);          } // GET
    static paymentDetails( id: number )               { return url(`/finance/payment/${id}`);    } // GET

    //e-commerce
    static stripePaymentMethodList()                  { return url( `/stripe/payment-method`                ); } //GET
    static stripePaymentMethodDetails( id: string )   { return url( `/stripe/payment-method/${id}`          ); } //GET
    static stripeConfig()                             { return url( `/stripe/config`                        ); } //POST
    static stripePaymentMethodUpdateExp()             { return url( `/stripe/payment-method`                ); } //POST
    static stripePaymentMethodDelete( id: string )    { return url( `/stripe/payment-method/${id}/delete`   ); } //POST

    //Footnotes
    //  #1: Why aren't these urls operating at a higher level?  For example, why not subscribeToMonthly plan?
    //      It's because we're using Stripe elements and it seems this is the way they like to do things
    //      We'll still have our stuff such as subscribeToMonthlyPlan but it will usually be the last step (I think)
}