import { Link, useLoaderData, useParams } from "react-router-dom";
import { LocalDateTime } from "@js-joda/core";
import { ReservationCard } from "./Controls/ReservationCard";
import { PageTitle } from "./Controls/PageTitle";
import { Col, Container, Row } from "react-bootstrap";
import { Reservation } from "./Data/ApiTypes";

export function UserReservationsPage() {
    const { time }     = useParams();
    const reservations = useLoaderData() as Reservation[];
    const now          = LocalDateTime.now();
    const futureFilter = ( r: Reservation ) => r.start.compareTo( now ) > 0 || r.end.compareTo( now ) > 0;
    const pastFilter   = ( r: Reservation ) => r.end.compareTo( LocalDateTime.now() ) < 0;
    const upcoming     = { heading: "",     linkText: "Past",    linkUrl: "past",     filter: futureFilter, word: "past"     };
    const past         = { heading: "Past", linkText: "Back to", linkUrl: "upcoming", filter: pastFilter  , word: "upcoming" };
    
    let entry = upcoming;
    if( time === "past" ) { 
        entry = past;
    }

    const { heading, linkText, linkUrl, filter, word } = entry;
    const list = reservations.filter( filter ).toSorted( (a, b) => a.start.compareTo( b.start ) );

    return <Container fluid>
        <Row>
            <Col>
                <div className="d-grid align-items-center">
                    <PageTitle className="grid-col-1">
                        {heading} Reservations
                    </PageTitle>
                    <Link to={`/user/reservation/${linkUrl}`} className="grid-col-2 text-end">
                        {linkText} Reservations
                    </Link>
                </div>
                {list.length === 0 && <div>You have no {word} reservations</div>}
                {list.map( r => <ReservationCard key={r.reservationId} reservation={r} /> )}
            </Col>
        </Row>
    </Container>;
}