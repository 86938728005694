import { Card, Col, Container, Row } from "react-bootstrap";
import { useLoaderData, useNavigate, useRouteLoaderData } from "react-router-dom";
import { FacilityParkerBalance, Order } from "./Data/ApiTypes";
import { PageTitle       } from "./Controls/PageTitle";
import { showMoney       } from "./Data/Money";
import { facilityImg     } from "./Data/Common";
import { showOrderType   } from "./Data/Finance";
import { showInstantDate } from "./Data/Date";
import { OrderPaidBadge  } from "./Controls/OrderPaidBadge";
import { Button } from "./Controls/Button";

export function UserOrdersPage() {
    const nav = useNavigate();
    const orders = useLoaderData() as Order[];
    const balances = useRouteLoaderData( "/user" ) as FacilityParkerBalance[];
    return <Container fluid>
        <Row>
            <Col>
                <PageTitle>
                    Orders
                </PageTitle>
                <div>
                { balances.map( b => <Button className="w-100" onClick={ () => nav( `/user/${b.facility.facilityId}/pay` )}>
                    Pay {showMoney( b.balance )} to {b.facility.name}
                </Button> ) }
                </div>
                {orders.length === 0 && <>
                    You haven't placed any orders
                </>}
                {orders.map( order => {
                    const facility = order.facility;
                    return <Card key={order.orderId} className="my-2" style={{ cursor: "pointer" }}
                                 onClick={() => nav(`/user/order/${order.orderId}`)}>
                        <Card.Header className="text-start fw-bold d-flex gap-2 align-items-center">
                            <OrderPaidBadge balance={order.balance.balance} /> #{order.orderId} {showOrderType( order.type )}
                        </Card.Header>
                        <Card.Body className="purchase-layout gap-2">
                            <div className="grid-area-a">
                                <img className="facility-result-pic"
                                    src={facilityImg( facility.listImageId )}
                                    style={{ aspectRatio: "1 / 1", objectFit: "cover" }} />
                            </div>
                            <div className="grid-area-b">
                                <div className="fw-bold">
                                    {facility.name}
                                </div>
                                <div>
                                    {order.summary}
                                </div>
                            </div>
                            <div className="grid-area-c position-relative" style={{ justifySelf: "end" }}>
                                <div className="fs-4 fw-bold">
                                    {showMoney( order.price )}
                                </div>
                                <div style={{ fontSize: "0.75em", position: "relative", top: "-5%", left: "0.05em" }} className="text-end">
                                    {showInstantDate( order.createdWhen, facility.timeZone )}
                                </div>
                            </div>
                            <div className="grid-area-d text-end d-flex gap-2">
                            </div>
                        </Card.Body>
                    </Card>;
                } )}
            </Col>
        </Row>
    </Container>;
}