
import { Link, useNavigate } from "react-router-dom";
import { showMoneyShort } from "../Data/Money";
import { computeCharge } from "../Data/Rates";
import { Duration, LocalDateTime } from "@js-joda/core";
import { showDurationShort } from "../Data/Date";
import { PersonWalking } from "react-bootstrap-icons";
import { Button } from "./Button";
import { hourlyCheckoutAtom } from "../Data/Atoms";
import { useAtom } from "jotai";
import { Facility } from "../Data/ApiTypes";
import { facilityImg } from "../Data/Common";

export interface ResultHourlyEntryProps {
    facility:     Facility;
    onClick:      () => void;
    onRatesClick: () => void;
    className?:   string;
    start:        LocalDateTime;
    end:          LocalDateTime;
}

export function ResultHourlyEntry( props: ResultHourlyEntryProps ) {
    const nav = useNavigate();
    const [hourlyCheckout, setHourlyCheckout] = useAtom( hourlyCheckoutAtom );
    const { name, hourlyRates, walkTime = 5, listImageId } = { ...props.facility };
    const { className = "", facility, start, end  } = { ...props };

    function viewRatesClick( e: React.MouseEvent<HTMLAnchorElement, MouseEvent> ) {
        e.stopPropagation();
        props.onRatesClick();
        return false;
    }

    function bookNowClick( e: React.MouseEvent<HTMLButtonElement, MouseEvent> ) {
        e.stopPropagation();
        setHourlyCheckout( { facility: facility, start: start, end: end, vehicles: [] })
        nav( "/checkout/hourly" );
        return false;
    }

    return <div className={`facility-result rounded-4 p-4 mb-3 shadow border border-2 ${className}`}
                onClick={ () => props.onClick() }>
        <div>
            <img className="facility-result-pic"
                 src={facilityImg( listImageId )}
                 style={{ aspectRatio: "1 / 1", objectFit: "cover" }} />
        </div>
        <div className="facility-result-info">
            <div className="grid-col-1">
                <div>
                    {name}
                </div>
                <div className="d-flex align-items-center">
                    <PersonWalking />&thinsp;{walkTime} min
                </div>
            </div>
            <div className="grid-col-2 position-relative">
                <div className="fs-5 fw-bold">
                    {showMoneyShort( computeCharge( hourlyRates[0], start, end ) )}
                </div>
                <div style={{ fontSize: "0.75em", position: "relative", top: "-5%", left: "0.05em" }}>
                    for {showDurationShort( Duration.between( start, end ) )}
                </div>
            </div>
            <div className="facility-result-bottom gap-1">
                <div style={{ justifySelf: "center", alignSelf: "center" }} className="">
                    <Link to="" className="w-100" onClick={ viewRatesClick }>
                        Rates
                    </Link>
                </div>
                <div className="w-100">
                    <Button className="w-100" onClick={ bookNowClick }>Book</Button>
                </div>
            </div>
        </div>
    </div>;
}