import {
    ChangeEmailReqApi,
    ChangeNameReqApi,
    ChangePasswordReqApi,
    ChangePhoneReqApi,
    FacilityApi,
    FacilityParkerBalanceApi,
    InvoiceApi,
    LogInReqApi,
    OperatorApi,
    OrderApi,
    OrderDetailsApi,
    OrderPayInitReqApi,
    OrderPayInitRespApi,
    ParkerApi,
    PaymentApi,
    ReservationApi,
    ReserveConfirmReqApi,
    ReserveInitReqApi,
    ReserveInitRespApi,
    ResetCodeCheckReqApi,
    ResetPasswordReqApi,
    SettleInitRespApi,
    SignUpReqApi,
    SubListEntryApi,
    SubscribeConfirmReqApi,
    SubscribeConfirmRespApi,
    SubscribeInitReqApi,
    SubscribeInitRespApi,
    SubscribeInviteInitReqApi,
    SubscribeInviteInitRespApi,
    SubscribeUpdateReqApi,
    SubscriptionApi,
    SubscriptionParkerApi,
    SubscriptionParkerChangeReqApi,
    SubscriptionParkerInviteReqApi,
    SubscriptionParkerRemoveReqApi,
    VehicleAddReqApi,
    VehicleDeleteReqApi} from "./ApiTransport";
import { ApiUrl } from "./ApiUrl";
import { OkResult, Result } from "./Result";
import { ParkerPlanInvite, PaymentMethodResp, SettleInitReq, SubscribeInviteInitReq, SubscribeInvitePreviewReq, SubscribePreviewReq, Vehicle } from "./ApiTypes";
import { parseParkerPlanInvite, parseSubscribeInitResp, parseSubscribeInviteInitResp, parseSubscribeInvitePreviewResp, parseSubscribePreviewResp, parseSubscribeUpdateDecreaseResp, parseSubscribeUpdateDeferResp, parseSubscriptionGuest, parseVehicleLinkResp } from "./ApiParse";
import { emitSubscribeInviteInitReq, emitSubscribeInvitePreviewReq, emitSubscribePreviewReq } from "./ApiEmit";

export const notSet = ( a: any ) => a === null;

export type SignUpResp         = ParkerApi;
export type LogInResp          = ParkerApi;
export type ChgPassResp        = ParkerApi;
export type ChgNameResp        = ParkerApi;
export type ChgPhoneResp       = ParkerApi;
export type ChgEmailResp       = ParkerApi;
export type ResetPassResp      = ParkerApi;
export type ResetCodeCheckResp = ParkerApi;

export interface ForgotPassReq  { emailAddress: string; }
export type ForgotPassResp = string;

export interface StripeConfigResp {
    publishableKey: string;
    stripeAccount:  string;
};

export interface SetupIntentCreateResp {
    clientSecret:                string;
    customerSessionClientSecret: string;
}

export interface PaymentIntentCreateResp {
    clientSecret:  string;
}

export interface VehicleData {
    make:               string;
    model:              string;
    color:              string;
    stateCode:          string;
    licensePlateNumber: string;
}

const parseArray = <TValApi, TVal>( func: ( api: TValApi ) => TVal ) => ( x: TValApi[] ) => x.map( func );

const getArgs = (): RequestInit => {
    return {
        method:      "GET",
        credentials: "include"
    }
}

const jsonArgs = ( verb: string, payload: any ): RequestInit => {
    return {
        method:      verb,
        headers:   { "Content-Type": "application/json" },
        body:        JSON.stringify( payload ),
        credentials: "include"
    }
}

const postArgs = ( payload: any ) => jsonArgs( "POST",   payload );
const resOk    = <T>( value: T ): OkResult<T>          => ({ isOk: true, value: value })
const ok       = <T>( value: T ): Promise<OkResult<T>> => Promise.resolve( resOk( value ) );
const okDelay = <TErr, TVal>( value: TVal ): Promise<Result<TErr, TVal>> =>
    new Promise( ( resolve ) =>
        setTimeout( () => { resolve( resOk( value ) ); }, 500 )
);

const api = <TErr, TVal>( url: string, init: RequestInit = {} ): Promise<Result<TErr, TVal>> => {
    return fetch( url, init )
        .then( response => {
            const json   = response.json();
            const isOk = (response.status == 200);
            return json.then( data => {
                if( isOk ) {
                    return { isOk: true, value: data };
                }
                return { isOk: false, error: data }
            } );
        } );
}

const api2 = <TValApi, TVal>( url: string, init: RequestInit = {}, f: (x: TValApi) => TVal ): Promise<Result<string, TVal>> => {
    return fetch( url, init )
        .then( response => {
            const json = response.json();
            const isOk = ( response.status == 200 );
            return json.then( data => {
                if( isOk ) {
                    const parsed = f( data );
                    return { isOk: true, value: parsed };
                }
                return { isOk: false, error: data }
            } );
        } );
}

export class Api {

    //operator
    static opDetails() { return api<string, OperatorApi>( ApiUrl.opDetails(), getArgs() ); }

    //auth
    static amILoggedIn        = (                           ) => api<string, LogInResp>         ( ApiUrl.amILoggedIn(),        getArgs(      ) );
    static signUp             = ( req: SignUpReqApi         ) => api<string, SignUpResp>        ( ApiUrl.signUp(),             postArgs( req ) );
    static logIn              = ( req: LogInReqApi          ) => api<string, LogInResp>         ( ApiUrl.logIn(),              postArgs( req ) );
    static logOut             = (                           ) => api<string, LogInResp>         ( ApiUrl.logOut(),             postArgs( {}  ) );
    static forgotPassword     = ( req: ForgotPassReq        ) => api<string, ForgotPassResp>    ( ApiUrl.forgotPassword(),     postArgs( req ) );    
    static resetCodeCheck     = ( req: ResetCodeCheckReqApi ) => api<string, ResetCodeCheckResp>( ApiUrl.resetCodeCheck(),     postArgs( req ) );
    static resetPassword      = ( req: ResetPasswordReqApi  ) => api<string, ResetPassResp>     ( ApiUrl.resetPassword(),      postArgs( req ) );    
    static changeName         = ( req: ChangeNameReqApi     ) => api<string, ChgNameResp>       ( ApiUrl.changeName(),         postArgs( req ) );
    static changePhoneNumber  = ( req: ChangePhoneReqApi    ) => api<string, ChgPhoneResp>      ( ApiUrl.changePhoneNumber(),  postArgs( req ) );
    static changeEmailAddress = ( req: ChangeEmailReqApi    ) => api<string, ChgEmailResp>      ( ApiUrl.changeEmailAddress(), postArgs( req ) );    
    static changePassword     = ( req: ChangePasswordReqApi ) => api<string, ChgPassResp>       ( ApiUrl.changePassword(),     postArgs( req ) );

    //facilities
    static facilitySearch    = (            ) => api<string, FacilityApi[] >( ApiUrl.facilitySearch()      /* no need to log in */   );
    static facilityDetails   = ( id: number ) => api<string, FacilityApi   >( ApiUrl.facilityDetails( id ) /* no need to log in */   );

    //stripe
    static stripeConfig              = ()             => api<string, StripeConfigResp>   ( ApiUrl.stripeConfig(),                   postArgs( {} ) );
    static stripePaymentMethodList   = ()             => api<string, PaymentMethodResp[]>( ApiUrl.stripePaymentMethodList(),        getArgs()      );
    static stripePaymentMethodGet    = ( id: string ) => api<string, PaymentMethodResp  >( ApiUrl.stripePaymentMethodDetails( id ), getArgs()      );
    static stripePaymentMethodDelete = ( id: string ) => api<string, string>   ( ApiUrl.stripePaymentMethodDelete( id ),   postArgs( {} ) );

    //vehicles
    static vehicleList    = ()                       => api<string, Vehicle[]>( ApiUrl.vehicleList(),      getArgs() );
    static vehicleGet     = ( id: number )           => api<string, Vehicle>  ( ApiUrl.vehicleDetails(id), getArgs() );
    static vehicleAdd     = ( vehicle: VehicleData ) => api<string, Vehicle>  ( ApiUrl.vehicleAdd(),       postArgs( vehicle ) );
    static vehicleDelete  = ( id: number        )    => api<string, Vehicle>  ( ApiUrl.vehicleDelete(id),  postArgs( {} ) );

    //reservations
    static reservationList     = ()             => api<string, ReservationApi[]>( ApiUrl.reservationList(),        getArgs(     ) );
    static reservationDetails  = ( id: number ) => api<string, ReservationApi>  ( ApiUrl.reservationDetails( id ), getArgs(     ) );
    static reservationCancel   = ( id: number ) => api<string, any>             ( ApiUrl.reservationCancel( id ),  postArgs( {} ) );

    static reservationInit( req: ReserveInitReqApi ) {
        return api<string, ReserveInitRespApi>( ApiUrl.reservationInit(), postArgs( req ) ); }

    static reservationConfirm( req: ReserveConfirmReqApi ) {
        return api<string, ReserveInitRespApi>( ApiUrl.reservationConfirm(), postArgs( req ) ); }

    static subscriptionPreview( planId: number, req: SubscribePreviewReq ) {
        return api2( ApiUrl.subscribePreview( planId ), postArgs( emitSubscribePreviewReq( req ) ), parseSubscribePreviewResp ); }

    static subscriptionInvitePreview( planId: number, req: SubscribeInvitePreviewReq ) {
        return api2( ApiUrl.subscribeInvitePreview( planId ), postArgs( emitSubscribeInvitePreviewReq( req ) ), parseSubscribeInvitePreviewResp ); }    

    //plans and subscriptions
    static subscriptionInit( req: SubscribeInitReqApi ) {
        return api<string, SubscribeInitRespApi>( ApiUrl.subscriptionInit(), postArgs( req ) ); }

    static subscriptionInviteInit( req: SubscribeInviteInitReq ) {
        return api2( ApiUrl.subscriptionInviteInit(), postArgs( emitSubscribeInviteInitReq( req ) ), parseSubscribeInviteInitResp ); }

    static subscriptionUpdateInit(  req: SubscribeUpdateReqApi ) { return api2( ApiUrl.subscriptionUpdateInit(),  postArgs( req ), parseSubscribeInitResp           ); }
    static subscriptionUpdateDecr(  req: SubscribeUpdateReqApi ) { return api2( ApiUrl.subscriptionUpdateDecr(),  postArgs( req ), parseSubscribeUpdateDecreaseResp ); }
    static subscriptionUpdateDefer( req: SubscribeUpdateReqApi ) { return api2( ApiUrl.subscriptionUpdateDefer(), postArgs( req ), parseSubscribeUpdateDeferResp    ); }

    static subscriptionConfirm( req: SubscribeConfirmReqApi ) {
        return api<string, SubscribeConfirmRespApi>( ApiUrl.subscriptionConfirm(), postArgs( req ) ); }

    static subscriptionPause( subscriptionId: number ) {
        return api<string, string>( ApiUrl.subscriptionPause( subscriptionId ), postArgs( {} ) ); }

    static subscriptionList()                          { return api<string, SubListEntryApi[]>( ApiUrl.subscriptionList(),   getArgs()      ); }
    static subscriptionDetails( id: number )           { return api<string, SubscriptionApi>(   ApiUrl.subscriptionDetails( id ), getArgs() ); }
    static subscriptionGuestDetails( id: number )      { return api2( ApiUrl.subscriptionGuestDetails( id ), getArgs(), parseSubscriptionGuest ); }
    static subscriptionSetupPayment( id: number )      { return api<string, SetupIntentCreateResp>( ApiUrl.subscriptionSetupPayment( id ), postArgs( {} ) ); }
    static planTermsGet(  planId: number )             { return api<string, string>( ApiUrl.planTerms( planId ), getArgs() ); }
    static planInviteGet( parkerPlanInviteId: number ) { return api2( ApiUrl.planInviteDetails( parkerPlanInviteId ), getArgs(), parseParkerPlanInvite ); }    
    
    //sub-parker management
    static subParkerInvite( subscriptionId: number, req: SubscriptionParkerInviteReqApi )
        { return api<string, SubscriptionParkerApi>( ApiUrl.subParkerCreate( subscriptionId ), postArgs( req ) ); }

    static subParkerChange( subscriptionId: number, req: SubscriptionParkerChangeReqApi )
        { return api<string, boolean>( ApiUrl.subParkerUpdate( subscriptionId ), postArgs( req ) ); }

    static subParkerRemove( subscriptionId: number, req: SubscriptionParkerRemoveReqApi )
        { return api<string, boolean>( ApiUrl.subParkerDelete( subscriptionId ), postArgs( req ) ); }

    //subscription vehicle management
    static subscriptionVehicleAdd( subscriptionId: number, req: VehicleAddReqApi )
        { return api2( ApiUrl.subscriptionVehicleAdd( subscriptionId), postArgs(req), parseVehicleLinkResp ); }

    static subscriptionVehicleDelete( subscriptionId: number, vehicleId: number, req: VehicleDeleteReqApi )
        { return api( ApiUrl.subscriptionVehicleDelete( subscriptionId, vehicleId), postArgs(req) ); }

    //finance endpoints
    static facilityParkerBalanceList(            ) { return api<string, FacilityParkerBalanceApi[]>( ApiUrl.facilityParkerBalanceList(), getArgs()       ); }
    static orderPayInit( req: OrderPayInitReqApi ) { return api<string, OrderPayInitRespApi>(        ApiUrl.orderPayInit(),              postArgs( req ) ); }
    static settleInit  ( req: SettleInitReq      ) { return api<string, SettleInitRespApi>(          ApiUrl.settleInit(),                postArgs( req ) ); }

    //orders
    static orderList()                  { return api<string, OrderApi[]>     ( ApiUrl.orderList(),      getArgs() ); }
    static orderDetails( id: number )   { return api<string, OrderDetailsApi>( ApiUrl.orderDetails(id), getArgs() ); }

    //invoices
    static invoiceList()                { return api<string, InvoiceApi[]> ( ApiUrl.invoiceList(),        getArgs() ); }
    static invoiceDetails( id: number ) { return api<string, InvoiceApi>   ( ApiUrl.invoiceDetails( id ), getArgs() ); }

    //payments
    static paymentList()                { return api<string, PaymentApi[]> ( ApiUrl.paymentList(),        getArgs() ); }
    static paymentDetails( id: number ) { return api<string, PaymentApi>   ( ApiUrl.paymentDetails( id ), getArgs() ); }
}