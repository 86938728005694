import { ChronoUnit, LocalDate, LocalDateTime, LocalTime } from "@js-joda/core";

//spotsync
import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { HourlyForm      } from "../Data/Atoms";
import { createLocalDate, nearest30, showBrowserDate, showBrowserTime, showTime } from "../Data/Date";
import { Input           } from "./Input";
import { LocationInput   } from "./LocationInput";
import { createLocalDateTime } from "../Data/Date";
import { Setter          } from "../Data/Types";
import { useEffect } from "react";
import { Select } from "./Select";

export interface HourlySearchProps {
    register: UseFormRegister<HourlyForm>;
    watch:    UseFormWatch<HourlyForm>;
    setValue: UseFormSetValue<HourlyForm>;
    errors:   FieldErrors <HourlyForm>;
    setPlace: Setter<google.maps.places.PlaceResult | null>
}

function getAllTimes() {
    const times: Array<LocalTime> = [];
    let time = LocalTime.of( 0, 0 );
    for( let i = 0; i < 48; i++ ) {
        times.push ( time );
        time = time.plusMinutes( 30 );
    }
    return times;
}

function timeOption( time: LocalTime ) {
    const str = showBrowserTime( time );
    return <option key={str} value={str}>
        {showTime( time )}
    </option>;
}

function getCheckInTimes( checkInDate: LocalDate, now: LocalDateTime ) {
    if( now.toLocalDate().isBefore( checkInDate ) ) {
        return getAllTimes().map( timeOption );
    }
    const allTimes    = getAllTimes();
    const nearest     = nearest30( now ).toLocalTime().truncatedTo( ChronoUnit.MINUTES );
    const filtered    = allTimes.filter( t => {
        const expr = t.equals( nearest ) || t.isAfter( nearest );
        return expr;
    } );
    const timeOptions = filtered.map( timeOption );
    return timeOptions;
}

function getCheckOutTimes( checkInDateTime: LocalDateTime, checkOutDate: LocalDate ) {
    const opts = [];
    let time = LocalTime.of( 0, 0 );
    if( !checkInDateTime.toLocalDate().equals( checkOutDate ) ) {
        return getAllTimes().map( timeOption );
    } //else
    time = LocalTime.of( checkInDateTime.hour(), checkInDateTime.minute() ).plusMinutes( 30 );
    while( time.isBefore( LocalTime.of( 23, 30 ) ) ) {
        opts.push( timeOption( time ) );
        time = time.plusMinutes( 30 );
    }
    opts.push( timeOption( time ) );
    return opts;
}

export function HourlySearch( props: HourlySearchProps ) {
    const { register, errors, watch, setValue } = { ...props };
    const formVals      = watch();
    const now           = nearest30( LocalDateTime.now() );
    const minEntry      = showBrowserDate( now.toLocalDate() );
    const entryDateTime = createLocalDateTime( formVals.enterAfterDate, formVals.enterAfterTime );
    const exitDateTime  = createLocalDateTime( formVals.exitBeforeDate, formVals.exitBeforeTime );

    useEffect( () => {
        const lastCheckInOptionSelected = entryDateTime.toLocalTime().equals( LocalTime.of( 23, 30 ) );
        const checkInIsAfterCheckOut    = entryDateTime.isAfter( exitDateTime );
        if( lastCheckInOptionSelected || checkInIsAfterCheckOut ) {
            const newExitTime = entryDateTime.plusMinutes( 30 );
            setValue( "exitBeforeDate", showBrowserDate( newExitTime.toLocalDate() ) );
            setValue( "exitBeforeTime", showBrowserTime( newExitTime.toLocalTime() ) );
            // setValue( "enterAfterDate", showBrowserDate( exitDateTime.toLocalDate()  ) );
            // setValue( "enterAfterTime", showBrowserTime( exitDateTime.toLocalTime()  ) );
            // setValue( "exitBeforeDate", showBrowserDate( entryDateTime.toLocalDate() ) );
            // setValue( "exitBeforeTime", showBrowserTime( entryDateTime.toLocalTime() ) );
        }
    }, [formVals] );

    const localDate = LocalDate.now();
    const localTime = LocalTime.now();
    const isEntryToday = localDate.equals( createLocalDate( formVals.enterAfterDate ) );
    const minEntryTime  = isEntryToday ? showBrowserTime( localTime ) : showBrowserTime( LocalTime.of( 0, 0, 0, 0 ) );

    const minExit = showBrowserDate( entryDateTime.toLocalDate() );
    const minExitTime = localDate.equals( createLocalDate( formVals.enterAfterDate ) )
        ? showBrowserTime( localTime ) : showBrowserTime( LocalTime.of( 0, 0, 0, 0 ) );

    //not needed for the iOS time input
    const checkInTimes  = getCheckInTimes( createLocalDate( formVals.enterAfterDate ), now );
    const checkOutTimes = getCheckOutTimes( entryDateTime, createLocalDate( formVals.exitBeforeDate ) );
    return <div>
        <LocationInput onPlaceSelect={ (p) => props.setPlace( p ) }
                       error={errors.location}
                       {...register( "location", { required: "Location is required" } ) } />
        <div id="landing-hourly-dates">
            <div>
                <Input
                    min={minEntry}
                    {...register( "enterAfterDate", { required: "Check-In Date is required",
                                                /*  { value: minEntry, message: "Check-In Date must be in the future"  */ } ) }
                 // icon={<Calendar />}
                    type="date"
                    label="Check-In"
                    placeholder="Today"
                    error={errors.enterAfterDate} />
            </div>
            <div>
                <Select
                    label="&nbsp;"
                    children={checkInTimes}
                    {...register( "enterAfterTime", { required: "Check-In Time is required" } )} />
            </div>
            <div>
                <Input
                    min={minExit}
                    {...register( "exitBeforeDate", { required: "Check-Out Date is required",
                                                /*  { value: minExit, message: "Check-Out Date must be after Check-In"  */ } ) }
                    type="date"
                    label="Check Out"
                    placeholder="Today"
                    error={errors.exitBeforeDate} />
            </div>
            <div>
                <Select label="&nbsp;"
                    children={checkOutTimes}
                    {...register( "exitBeforeTime", { required: "Check-Out Time is required" } )} />
            </div>
        </div>
    </div>;
}

