//third party
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Row, Col, Card } from "react-bootstrap";
import { DateTimeFormatter } from "@js-joda/core";

//stripe
import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions } from "@stripe/stripe-js";

//spotsync
import { authAtom, hourlyCheckoutAtom, redirectAtom   } from "./Data/Atoms";
import { push                           } from "./Data/Array";
import { Button                         } from "./Controls/Button";
import { ReservationDetailsHourly       } from "./Controls/ReservationDetailsHourly";
import { CardHeader                     } from "./Controls/CardHeader";
import { CheckoutIndicator              } from "./Controls/CheckoutIndicator";
import { AddVehicleModal                } from "./Controls/AddVehicleModal";
import { VehicleCard                    } from "./Controls/VehicleCard";
import { CheckoutEmail                  } from "./Controls/CheckoutEmail";
import { Content                        } from "./Controls/Content";
import { ChooseVehicleModal             } from "./Controls/ChooseVehicleModal";
import { ReserveInitResp, Vehicle       } from "./Data/ApiTypes";
import { processResult                  } from "./Data/Result";
import { stripePromise                  } from "./Data/ApiStripe";
import { Api                            } from "./Data/Api";
import { ReserveInitReqApi              } from "./Data/ApiTransport";
import { parseReserveInitResp           } from "./Data/ApiParse";
import { StripeHourlyCheckoutForm       } from "./StripeHourlyCheckoutForm";
import { appearanceOptions, stripeFonts } from "./Controls/StripeAppearance";
import { Hyperlink } from "./Controls/Hyperlink";

enum CheckoutStep {
    Initial,
    Account,
    Reservation,
    Payment
}

interface State<TState, TContext> {
    name: string;
    cond: ( ctx: TContext ) => boolean;
    entr: ( ctx: TContext ) => TContext;
    exit: ( ctx: TContext ) => boolean;
}

export interface EmailForm {
    emailAddress: string;
}

export function CheckoutPageHourly() {
    const loc = useLocation();
    const nav = useNavigate();
    const [ auth,               setAuth               ] = useAtom( authAtom );
    const [ redirect,           setRedirect           ] = useAtom( redirectAtom );
    const [ checkoutUnd,        setCheckout           ] = useAtom( hourlyCheckoutAtom );
    const [ step,               setStep               ] = useState( CheckoutStep.Initial );
    const [ myVehicles,         setMyVehicles         ] = useState<Vehicle[]>( [] );
    const [ showChooseVehicle,  setShowChooseVehicle  ] = useState( false );
    const [ showAddVehicle,     setShowAddVehicle     ] = useState( false );
    const [ email,              setEmail              ] = useState<EmailForm>( { emailAddress: auth.isLoggedIn ? auth.account!.emailAddress : "" } );
    const [ reserveRequestResp, setReserveRequestResp ] = useState<ReserveInitResp>();
    const [ paymentVis,         setPaymentVis         ] = useState<"hidden" | "visible">( "hidden" );
    const checkout = checkoutUnd!;

    useEffect( () => {
        // if( auth.isLoggedIn ) {
        //     setEmail( { emailAddress: auth.account!.emailAddress } );
        //     setStep( CheckoutStep.Reservation );
        //     return;
        // }
        // //else
        // setEmail( { emailAddress: "" } );
        // setStep( CheckoutStep.Account );
        if( auth.isLoggedIn ) {
            setEmail( { emailAddress: auth.account!.emailAddress } );
            setStep( CheckoutStep.Reservation );
            return;
        }
        //else
        setRedirect( `${loc.pathname}${loc.search}` );
        nav( "/login" );
    }, [auth.isLoggedIn] );

    useEffect( () => {
        Api.vehicleList().then(
            res => processResult( res,
                val => setMyVehicles( val ),
                err => console.log( `Didn't retrieve vehicles because: ${err}`) ) ); //don't do anything if they didn't load
    }, [] );

    useEffect( () => {
        if( step === CheckoutStep.Payment ) {
            const reserveReq: ReserveInitReqApi = {
                facilityId: checkout.facility.facilityId,
                start:      checkout.start.format( DateTimeFormatter.ISO_LOCAL_DATE_TIME ),
                end:        checkout.end.format( DateTimeFormatter.ISO_LOCAL_DATE_TIME ),
            };
            Api.reservationInit( reserveReq ).then(
                res => processResult( res,
                    val => {
                        setReserveRequestResp( parseReserveInitResp( val ) );
                        setPaymentVis( "visible" );
                    },
                    err => console.error( err ) ) );
        }
    }, [step] );

    const options: StripeElementsOptions = {
        clientSecret:                reserveRequestResp?.paymentIntentClientSecret,
        customerSessionClientSecret: reserveRequestResp?.customerSessionClientSecret,
        appearance:                  appearanceOptions,
        fonts:                       stripeFonts
    };

    //review details
    const { register, handleSubmit: handleEmailSubmit, getValues }
        = useForm<EmailForm>( { defaultValues: { emailAddress: "" } } );

    function submitEmail( email: EmailForm ) {
        setEmail( email );
        setStep( CheckoutStep.Reservation );
    }

    const addVehicle  = ( v: Vehicle    ) => setCheckout( { ...checkout, vehicles: push( checkout.vehicles, v ) } );
    const setVehicles = ( vs: Vehicle[] ) => setCheckout( { ...checkout, vehicles: vs } );

    if( !checkout ) {
        return <Navigate to="/results" />;
    }

    return <Content fluid>
        <Row className="justify-content-center">
            <Col md="6">
                <AddVehicleModal show={showAddVehicle}
                                 setShow={setShowAddVehicle}
                                 onSubmit={addVehicle} />

                <ChooseVehicleModal vehicles={myVehicles}
                                    show={showChooseVehicle}
                                    setShow={setShowChooseVehicle}
                                    onSubmit={addVehicle} />

                <Card className="mb-2">
                    <CardHeader>
                        <CheckoutIndicator step={1} success={step > CheckoutStep.Account} /> Account Information
                    </CardHeader>
                    <Card.Body>
                        {email.emailAddress == "" && <>You must <Hyperlink to={`/login`}>log in</Hyperlink> to purchase parking.</>}
                        {email.emailAddress !== "" && <>Checking out as {email.emailAddress}</>}
                    </Card.Body>
                </Card>
                <Card className="mb-2">
                    <CardHeader>
                        <CheckoutIndicator step={2} success={step > CheckoutStep.Reservation} /> Reservation Details
                    </CardHeader>
                    {step === CheckoutStep.Reservation ? <Card.Body>
                        <ReservationDetailsHourly rates={checkout.facility.hourlyRates[0]}
                            start={checkout.start}
                            end={checkout.end} />
                        <div className="mt-2">
                            {checkout.vehicles.map( ( v, i ) => <VehicleCard
                                key={i}
                                vehicle={v}
                                deleteVehicle={ () => 0 }
                                vehicles={checkout.vehicles} /> )}
                        </div>
                        {/* {step === CheckoutStep.Reservation && checkout.vehicles.length === 0 && <div className="mt-2 d-flex gap-2">
                            {myVehicles.length > 0 && <Button className="w-100 mt-2" onClick={ () => setShowChooseVehicle( true ) }>
                                { checkout.vehicles.length > 0 ? "Add Existing Vehicle" : "Add Existing Vehicle"  }
                            </Button>}
                            <Button className="w-100 mt-2" onClick={ () => setShowAddVehicle( true ) }>
                                { checkout.vehicles.length > 0 ? "Add New Vehicle" : "Add New Vehicle"  }
                            </Button>
                        </div>} */}
                        {/* && checkout.vehicles.length > 0 */}
                        {step === CheckoutStep.Reservation
                        && <Button className="w-100 mt-2" onClick={ () => setStep( CheckoutStep.Payment ) }>
                            Continue to Payment
                        </Button>}
                    </Card.Body> : <span>
                    </span>}
                </Card>
                <Card>
                    <CardHeader>
                        <CheckoutIndicator step={3} success={step > CheckoutStep.Payment} /> Payment Details
                    </CardHeader>
                    {step === CheckoutStep.Payment && <Card.Body>
                        <div style={{ visibility: paymentVis }}>
                            {reserveRequestResp && <Elements stripe={stripePromise} options={options}>
                                <StripeHourlyCheckoutForm reservationDetails={reserveRequestResp} />
                            </Elements>}
                        </div>
                    </Card.Body>}
                </Card>
            </Col>
        </Row>
    </Content>;
}