import { Input          } from "./Input";
import { MonthlyForm    } from "../Data/Atoms";
import { LocationInput  } from "./LocationInput";
import { FieldErrors,
         UseFormRegister,
         UseFormSetValue,
         UseFormWatch } from "react-hook-form";
import { LocalDate } from "@js-joda/core";
import { showBrowserDate } from "../Data/Date";
import { Setter } from "../Data/Types";

export interface MonthlySearchProps {
    register: UseFormRegister<MonthlyForm>;
    watch:    UseFormWatch<MonthlyForm>;
    setValue: UseFormSetValue<MonthlyForm>;
    errors:   FieldErrors<MonthlyForm>;
    setPlace: Setter<google.maps.places.PlaceResult | null>;
}

export function MonthlySearch( props: MonthlySearchProps ) {
    const { register, errors } = { ...props };
    const minEntryDate = showBrowserDate( LocalDate.now() );
    return <div>
        <div>
            <LocationInput
                onPlaceSelect={ (p) => props.setPlace( p ) }
                error={errors.location}
                {...register( "location", { required: "Location is required" } )} />
        </div>
        <div id="monthly-search-dates">
            <Input
                min={minEntryDate}
                {...register( "startDate", { required: "Start Date is required",
                    min: { value: minEntryDate, message: "Start Date must be in the future" } } )}
                type="date"
                label="Start Date"
                placeholder="Today"
                explanation="The first day you need to park"
                error={errors.startDate} />
        </div>
    </div>;
}